import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "..//css/components/reactmodal.css";

export default function VarmeApp() {
    const navigate = useNavigate();

    return (
        <div className={""}>
            <div className={""}>
                <Link className={"primary-button"} onClick={(e) => {
                    e.preventDefault();
                    navigate(`/calculator/${crypto.randomUUID()}-5`)
                }}>ØS Kalkulator</Link>
                <Link className={"standard-button"} onClick={(e) => {
                    e.preventDefault();
                    navigate("/library")
                }}>Eksempel bibliotek </Link>
                <Link className={"standard-button"} onClick={(e) => {
                    e.preventDefault();
                    navigate('/my-projects')
                }}>
                    Mine prosjekter
                </Link>
            </div>
        </div>
    );
}