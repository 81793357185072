import './App.css';
import Library from "./components/Library";
import React from "react";
import NewCalculator from "./components/NewCalculator";
import {Link, Route, Router, Routes} from "react-router-dom";
import VarmeApp from "./components/VarmeApp";
import RoomProperties from "./components/RoomProperties";
import Results from "./components/Results";
import Room from "./components/Room";
import MyProjects from "./components/MyProjects";

function App() {
    return (
        <div className="App">
            <header className={"flex justify-between items-center pb-6"}>
                <Link to={'/'}>

                    <svg width="170" height="30" viewBox="0 0 287 52" fill="none" className={""}>
                        <g clipPath="url(#clip0_5_122)">
                            <path
                                d="M85.8018 14.0706H121.617C123.162 17.0436 124.147 20.3525 124.433 23.8588H88.5784C88.295 20.357 87.3261 17.0504 85.804 14.0706"
                                fill="#D2091E"/>
                            <path
                                d="M118.824 42.2119C114.041 48.1782 106.676 52 98.4144 52H66.4727C73.1846 50.9197 79.0487 47.3204 83.0617 42.2119H118.824Z"
                                fill="#D2091E"/>
                            <path
                                d="M124.433 28.1412C124.145 31.6453 123.162 34.9564 121.617 37.9294H85.804C87.3261 34.9496 88.2973 31.6407 88.5784 28.1412H124.433Z"
                                fill="#D2091E"/>
                            <path
                                d="M0 23.8588C0.287948 20.3525 1.27291 17.0436 2.81778 14.0706H38.6308C37.1088 17.0504 36.1375 20.357 35.8564 23.8588H0Z"
                                fill="#D2091E"/>
                            <path
                                d="M38.6308 37.9294H2.81778C1.27291 34.9564 0.287948 31.6475 0 28.1412H35.8541C36.1375 31.6407 37.1065 34.9496 38.6285 37.9294"
                                fill="#D2091E"/>
                            <path
                                d="M5.60812 9.78816C10.3913 3.82176 17.7568 0 26.0182 0H57.9599C51.2479 1.08026 45.3839 4.67734 41.3709 9.78816H5.60812Z"
                                fill="#D2091E"/>
                            <path
                                d="M98.4144 0C106.676 0 114.041 3.82176 118.824 9.78815H83.0617C81.19 7.40523 78.9184 5.35364 76.3452 3.73098L59.3928 52.0023H26.0182C17.7568 52.0023 10.3913 48.1805 5.60812 42.2141H41.3709C43.2402 44.5948 45.5141 46.6486 48.0874 48.2713L65.0398 0H98.4121H98.4144Z"
                                fill="#D2091E"/>
                            <path
                                d="M273.501 14.4042V37.8636H287V34.9496H276.716V27.2834H286.132V24.3694H276.716V17.3182H286.712V14.4042H273.501Z"
                                fill="#D2091E"/>
                            <path
                                d="M244.082 14.4042V37.8636H247.211V19.9644L255.248 37.8636H257.734L265.776 19.5604V37.8636H268.905V14.4042H264.823L256.585 33.1613L248.161 14.4042H244.082Z"
                                fill="#D2091E"/>
                            <path
                                d="M148.147 35.4716C146.744 35.4716 145.503 35.1221 144.452 34.4866L154.062 19.9394C155.131 21.5462 155.716 23.6818 155.716 26.135C155.716 31.5885 152.827 35.4716 148.147 35.4716ZM142.233 32.3306C141.163 30.7239 140.578 28.5883 140.578 26.135C140.578 20.6815 143.467 16.7985 148.145 16.7985C149.548 16.7985 150.791 17.148 151.843 17.7834L142.231 32.3306H142.233ZM155.901 17.1525L158.084 13.8482L155.542 12.1824L153.499 15.2757C151.968 14.4224 150.165 13.9549 148.145 13.9549C141.517 13.9549 137.217 18.9931 137.217 26.135C137.217 29.8252 138.366 32.9547 140.391 35.1175L138.208 38.4219L140.75 40.0876L142.793 36.9944C144.324 37.8454 146.127 38.3152 148.147 38.3152C154.775 38.3152 159.078 33.277 159.078 26.135C159.078 22.4449 157.928 19.3153 155.903 17.1525"
                                fill="#D2091E"/>
                            <path
                                d="M162.89 20.2186C162.89 28.3841 175.214 25.5722 175.214 31.3366C175.214 34.0668 173.025 35.4738 170.401 35.4738C166.777 35.4738 165.31 32.9003 165.31 30.6513H161.978C161.978 33.6969 164.076 38.3152 170.401 38.3152C175.877 38.3152 178.576 34.9178 178.576 31.3366C178.576 22.7036 166.251 25.2318 166.251 20.2186C166.251 18.0399 167.856 16.7985 170.388 16.7985C172.92 16.7985 174.565 18.7207 174.565 20.7088H177.854C177.854 17.8334 175.847 13.9572 170.401 13.9572C164.956 13.9572 162.89 17.2342 162.89 20.2208"
                                fill="#D2091E"/>
                            <path
                                d="M231.209 24.4579H233.803H227.813V17.3182H233.807C235.791 17.3205 237.397 18.9182 237.397 20.8881C237.397 22.858 235.791 24.4556 233.807 24.4579H231.211H231.209ZM240.329 20.8881C240.329 17.3091 237.409 14.4065 233.805 14.4042H233.803H224.687V37.8636H227.815V27.3719H231.52L236.495 37.8636H240.334L235.064 27.2493C238.067 26.6661 240.334 24.0426 240.334 20.8881"
                                fill="#D2091E"/>
                            <path
                                d="M186.974 14.4042L194.676 37.8636H198.038L205.737 14.4042H202.174L196.356 33.4064L190.402 14.4042H186.974Z"
                                fill="#D2091E"/>
                            <path
                                d="M210.57 27.3719L213.176 18.8614L215.842 27.3719H210.57ZM219.131 37.8636H222.557L214.855 14.4042H211.494L203.792 37.8636H207.357L209.709 30.1769H216.72L219.129 37.8636H219.131Z"
                                fill="#D2091E"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_5_122">
                                <rect width="287" height="52" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>


                </Link>
                <Link to={'/'} className={"flex items-center gap-2 text-xs font-semibold"}>
                    HJEM
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="w-6 h-6">
                        <path
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17 17.25H7C6.59 17.25 6.25 16.91 6.25 16.5C6.25 16.09 6.59 15.75 7 15.75H17C17.41 15.75 17.75 16.09 17.75 16.5C17.75 16.91 17.41 17.25 17 17.25ZM17 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H17C17.41 11.25 17.75 11.59 17.75 12C17.75 12.41 17.41 12.75 17 12.75ZM17 8.25H7C6.59 8.25 6.25 7.91 6.25 7.5C6.25 7.09 6.59 6.75 7 6.75H17C17.41 6.75 17.75 7.09 17.75 7.5C17.75 7.91 17.41 8.25 17 8.25Z"
                            fill="#151B33"/>
                    </svg>
                </Link>

            </header>
            <Routes>
                <Route path={"/"} element={<VarmeApp/>}/>
                <Route path={"/calculator"} element={<NewCalculator/>}/>
                <Route path={"/calculator/:id"} element={<NewCalculator/>}/>
                <Route path={"/room"} element={<Room/>}/>
                <Route path={"/library"} element={<Library/>}/>
                <Route path={"/library/:id"} element={<Library/>}/>
                <Route path={"/room-properties"} element={<RoomProperties/>}/>
                <Route path={"/room-properties/:id"} element={<RoomProperties/>}/>
                <Route path={"/results/:id"} element={<Results/>}/>
                <Route path={"/my-projects"} element={<MyProjects/>}/>
            </Routes>
        </div>
    );
}

export default App;
