import axios from "axios";

export const postProject = (jsonData) => {
    let id= jsonData.id
    let config = {
        method: 'post',
        url: `${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}`,
        data:jsonData
    };
return axios(config);
}
export const getProject = (projID) => {
    let id= projID
    let config = {
        method: 'get',
        url: `${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}`,
    };
    return axios(config);
}
export const getCalculations = (roundedArea, floorM2, power, maxWattPerM2, cableType) => {
    let config = {
        method: 'get',
        url: `${process.env.REACT_APP_OV_CALCULATIONS}/calculator/recommendations`,
        params: {
            m2: roundedArea,
            floorM2: floorM2,
            wattPerM2: power,
            maxWattPerM2: maxWattPerM2,
            productGroupId: cableType,
        },
    };
    return axios(config);
}
export const getCalculationsForLamiflex = (roundedArea,floorM2, power) => {
    let config = {
        method: 'get',
        url: `${process.env.REACT_APP_OV_CALCULATIONS}/calculator/lamiflex`,
        params: {
            m2: roundedArea,
            floorM2: floorM2,
            wattPerM2: power,

        },
    };
    return axios(config);
}
export const postImage = (jsonData, blob) => {
    let formData = new FormData();
    formData.append('file', blob, 'floorplan.png');
    let id= jsonData.id
    let config = {
        method: 'post',
        url: `${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}/floorplan`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    return axios(config);
}
export const getImage = () => {
    let id= ""
    let config = {
        method: 'get',
        url: `${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}/floorplan.png`,
    };
    return axios(config);
}
export const postLogo = (id,file) => {
    let formData = new FormData();
    formData.append('file', file);
    let config = {
        method: 'post',
        url: `${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}/logo`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    return axios(config);
}
export const getPdf = (id) => {
    let config = {
        method: 'get',
        url: `${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}.pdf`,
    };
    return axios(config);
}
