import {getMyProjectsRef} from "../utlils/functions";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getProject} from "../utlils/services";

export default function MyProjects() {
    const [cleared, setCleared] = useState(true);
    const [projects, setProjects] = useState(getMyProjectsRef().reverse());
    // let myProjects = getMyProjectsRef().reverse();
    // console.log("myProjects",myProjects);
    const MY_PROJECTS_KEY = "OEGLAND_PROJECTS"
    const handleDelete = (projectId) => {
        const updatedProjects = projects.filter(p => p.id !== projectId);
        setProjects(updatedProjects);
        localStorage.setItem(MY_PROJECTS_KEY, JSON.stringify(updatedProjects));
    };

    return (
        <div className={"mt-8"}>
            <div className={"flex justify-between"}>
                <p className={"text-2xl font-bold"}>Mine prosjekter</p>
                {/*<button className={""} onClick={(e) => {*/}
                {/*    e.preventDefault();*/}
                {/*    localStorage.clear();*/}
                {/*    setCleared(false);*/}
                {/*    setProjects([]);*/}
                {/*}}>*/}
                {/*    <svg className="w-8 h-8 text-gray-800 dark:text-black" aria-hidden="true"*/}
                {/*         fill="none" viewBox="0 0 24 24">*/}
                {/*        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
                {/*              d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>*/}
                {/*    </svg>*/}
                {/*</button>*/}
            </div>
            {projects.map(p => (
                <div key={p.id}>
                    {cleared && (
                        <ProjectItem project={p} onDelete={handleDelete}/>
                    )}
                </div>
            ))}
        </div>
    );
}
function ProjectItem({ project, onDelete }) {
    const handleDeleteClick = () => {
        onDelete(project.id);
    };

    return (
        <div>
        <Link className={"my-projects-container"} to={project.link}>
            <p className={"mt-3 text-gray-500 text-lg font-bold"}>{project.name}</p>
            <div className={"my-projects-details"}>
                <div className={"image-container"}>
                    <img className={""}
                         src={`${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${project.id}/floorplan.png`}
                         alt="image"/>
                </div>
                <div className={"flex-grow"}>
                    <p className={"text-gray-500 font-bold py-1"}>{project.roomType}</p>
                    <table className={"size-table "}>
                        <tbody>
                        <tr>
                            <td>Brutto areal</td>
                            <td className={"num"}><span>{project.areaGfa}</span></td>
                            <td className={"num"}>㎡</td>
                        </tr>
                        <tr className={"text-red-500"}>
                            <td>Hindring</td>
                            <td className={"num"}><span>{project.clipArea}</span></td>
                            <td className={"num"}>㎡</td>
                        </tr>
                        <tr className={"text-red-500"}>
                            <td>Avstand vegg</td>
                            <td className={"num"}><span>{project.wallDistance}</span></td>
                            <td className={"num"}>㎡</td>
                        </tr>
                        <tr>
                            <td>Varmekabel</td>
                            <td className={"num "}><span
                                className={"text-base "}>{project.cableArea}</span></td>
                            <td className={"num "}><span className={"text-base "}>㎡</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Link>
            <div className={"my-projects-delete-button"}>
            <button className={"flex gap-2 items-center text-gray-400 text-sm"} onClick={handleDeleteClick}>
                Slett
                <svg className="w-5 h-5 text-gray-200 mr-4 dark:text-black" aria-hidden="true"
                     fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                          d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                </svg>
            </button>
            </div>
        </div>
    );
}