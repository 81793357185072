import React, {useState} from 'react';
import Modal from 'react-modal';

export default function ClipsModal(props) {
    const [userInputWidth, setUserInputWidth] = useState(props.initialWidth.toString());
    const [userInputHeight, setUserInputHeight] = useState(props.initialHeight.toString());
    const [userInputClipName, setUserInputClipName] = useState(props.clipName);
    const [edit, setEdit] = useState(true);


    const toggleEdit = () => {
        setEdit(!edit);
    }
    const handleInputChangeWidth = (e) => {
        props.handleInputChange({
            target: {
                name: 'width',
                value: e.target.value,
            },
        });
        setUserInputWidth(e.target.value)
    };

    const handleInputChangeHeight = (e) => {
        props.handleInputChange({
            target: {
                name: 'height',
                value: e.target.value,
            },
        });
        setUserInputHeight(e.target.value)
    };

    const handleInputChangeClipName = (e) => {
        props.handleInputChange({
            target: {
                name: 'clipName',
                value: e.target.value,
            },
        });
        setUserInputClipName(e.target.value);
    }

    const handleSave = () => {
        const width = parseFloat(userInputWidth);
        const height = parseFloat(userInputHeight);
        props.userInputWidth(width);
        props.userInputHeight(height);
        props.setCustomClipName(userInputClipName);
        props.onRequestClose();
    };
    const handleClose = () => {
        props.onRequestClose();
    }
    const handleDelete = () => {
        props.delete();
        props.onRequestClose();
    };

    return (
        <div className={""}>
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                contentLabel="Resize"
                className="Content"
                overlayClassName="Overlay"
            >
                <dialog
                    className={"dialog"}
                >
                    <a href="#" className={"close-button"}
                       onClick={handleClose}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke="#151B33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <g opacity="0.4">
                                <path d="M9.16992 14.8299L14.8299 9.16992" stroke="#151B33" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.8299 14.8299L9.16992 9.16992" stroke="#151B33" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </a>


                    {edit && <div className={"flex gap-5"}>
                        <span className={"text-3xl"}>{userInputClipName}</span>
                        <button className={"rounded border border-gray-200 py-1 px-2 text-xs"} onClick={toggleEdit}>Legg
                            inn
                        </button>
                    </div>}
                    {!edit && <label htmlFor="clip-name" className={"size-label"}>
                        Hindring
                        <br/>
                        <input className={"size-input"}
                               type="text"
                               name={"clip-name"}
                               id={"clip-name"}
                               value={userInputClipName}
                               onChange={handleInputChangeClipName}
                        />
                    </label>}

                    <label htmlFor="lengde" className={"size-label"}>
                        Bredde
                        <br/>
                        <div className={"relative"}>
                        <input className={"size-input"}
                               type="tel"
                               name={"bredde"}
                               id={"bredde"}
                               value={userInputWidth}
                               onChange={handleInputChangeWidth}
                        />
                            <div className="size-label-span-container">
                                <span className="size-label-span">cm</span>
                            </div>
                        </div>
                    </label>
                    <label htmlFor="lengde" className={"size-label"}>
                        Lengde
                        <br/>
                        <div className={"relative"}>
                        <input className={"size-input"}
                               type="tel"
                               name={"lengde"}
                               id={"lengde"}
                               value={userInputHeight}
                               onChange={handleInputChangeHeight}
                        />
                            <div className="size-label-span-container">
                                <span className="size-label-span">cm</span>
                            </div>
                        </div>
                    </label>
                    <p className={"text-gray-500"}>Alle mål må oppgis i centimeter</p>
                    <div className={"flex w-full justify-between "}>

                        <a href="#" className={"ov-delete-button"}
                           onClick={handleDelete}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                                    stroke="#151B33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                    stroke="#151B33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M18.8499 9.13989L18.1999 19.2099C18.0899 20.7799 17.9999 21.9999 15.2099 21.9999H8.7899C5.9999 21.9999 5.9099 20.7799 5.7999 19.2099L5.1499 9.13989"
                                    stroke="#151B33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10.3301 16.5H13.6601" stroke="#151B33" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.5 12.5H14.5" stroke="#151B33" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                            Slett
                        </a>
                        <button className={"ov-button"}
                                onClick={handleSave}>OK
                        </button>
                    </div>
                </dialog>
            </Modal>
        </div>
    );
};

