import React, {useEffect, useRef, useState} from "react";
import {Layer, Rect, Stage, Text} from "react-konva";
import GFAModal from "./GFAModal";
import Rectangle from "./Rectangle";
import {
    calculateWallDistanceArea,
    convertValuesToPixels,
    deselectObstacles,
    handleObstacles, saveProjectReference,
    selectObstacles
} from "../utlils/functions"
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {postImage, postProject} from "../utlils/services";

export default function Room({
                                 room,
                                 selected,
                                 onSelect,
                                 setGfaWidthForArea,
                                 setGfaHeightForArea,
                                 setNfaHeightForArea,
                                 setNfaWidthForArea,
                                 libraryState,
                                 areaGFA,
                                 areaNFA
                             }) {
    const [inputWidth, setInputWidth] = useState(0);
    const [inputHeight, setInputHeight] = useState(0);
    const [inputGapVal, setInputGapVal] = useState(0);
    const [gfaWidth, setGfaWidth] = useState(0);
    const [gfaHeight, setGfaHeight] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [gapWidth, setGapWidth] = useState(0);
    const [gapHeight, setGapHeight] = useState(0);
    const [nfaWidth, setNfaWidth] = useState(0);
    const [nfaHeight, setNfaHeight] = useState(0);
    const [gapVal, setGapVal] = useState(0);
    const [gapValCoordinates, setGapValCoordinates] = useState(0);
    const [gfaWidthForDraw, setGfaWidthForDraw] = useState(0);
    const [gfaHeightForDraw, setGfaHeightForDraw] = useState(0);
    const [nfaWidthForDraw, setNfaWidthForDraw] = useState(0);
    const [nfaHeightForDraw, setNfaHeightForDraw] = useState(0);
    const [pixelScale, setPixelScale] = useState(0);
    const [gapValForDraw, setGapValForDraw] = useState(0);
    const [modal, setModal] = useState(false);
    const [shapes, setShapes] = useState(room.clips);
    const stageRef = useRef(null);
    const navigate = useNavigate();
    const [gfaX, setGfaX] = useState(0);
    const [gfaY, setGfaY] = useState(0);
    const [clipArea, setClipArea] = useState(0);
    const [obstacles, setObstacles] = useState([]);
    const [areaWithObstacles, setAreaWithObstacles] = useState(0);
    const [roundedArea, setRoundedArea] = useState(0);
    const [clipsArray, setClipsArray] = useState([]);
    const [wallDistance, setWallDistance] = useState(0);
    const location = useLocation();
    const [gfaModal, setGfaModal] = useState(false);
    const [floorM2, setFloorM2] = useState(0);
    const {id} = useParams();

    let projectName = "";
    let roomType = "";


    useEffect(() => {
        convertValuesToPixels(gfaWidth, gfaHeight, setPixelScale);
        if (pixelScale) {
            // setGapValForDraw((gapVal / pixelScale));
            setGfaWidthForDraw(gfaWidth / pixelScale);
            setGfaHeightForDraw(gfaHeight / pixelScale);
            setNfaWidthForDraw((nfaWidth / pixelScale) - gapValForDraw);
            setNfaHeightForDraw((nfaHeight / pixelScale) - gapValForDraw);
            setGapWidth((gfaWidth / pixelScale) - 6);
            setGapHeight((gfaHeight / pixelScale) - 6);
            setGfaX((gfaWidth / pixelScale) / 2);
            setGfaY((gfaHeight / pixelScale) / 2);
        }
    }, [gfaWidth, gfaHeight, pixelScale, selected, gapValForDraw])

    useEffect(() => {
        if (inputHeight > 0 || inputWidth > 0 || inputGapVal > 0) {
            setGfaWidth(inputWidth);
            setGfaHeight(inputHeight);
            setNfaWidth(inputWidth);
            setNfaHeight(inputHeight);
            setGapVal(inputGapVal);
        } else if (room && room.GFA) {
            setGfaWidth(room.GFA.width);
            setGfaHeight(room.GFA.height);
            setNfaWidth(room.NFA.width);
            setNfaHeight(room.NFA.height);
            setGapVal(room.gap.gapVal)
        }
    }, [room, inputHeight, inputWidth, gfaWidth, gfaHeight, inputGapVal]);



    useEffect(() => {
        setGapValForDraw(gapVal + 3)
        let gap = (gapVal + 3)
        setGapValCoordinates(gap / 2)
    }, [gapVal])

    useEffect(() => {
        if (selected) {
            setGfaWidthForArea(gfaWidth);
            setGfaHeightForArea(gfaHeight);
            setNfaWidthForArea(nfaWidth - gapVal);
            setNfaHeightForArea(nfaHeight - gapVal);
            let wallDistanceArea = calculateWallDistanceArea(gfaWidth,gfaHeight,gapVal);
           setWallDistance(parseFloat(wallDistanceArea));
        }
    }, [gfaWidth, gfaHeight, nfaWidth, nfaHeight, selected, gapVal])


    useEffect(() => {
        if (selected && shapes) {
            let obs = []
            shapes.forEach((shape) => {
                if (shape.crop) {
                    obs.push(shape)
                }
            })
            setObstacles(obs);
        }
    }, [selected, shapes])

    useEffect(() => {
        if (areaWithObstacles > 0) {
            if(areaWithObstacles % 1 !== 0){
            setRoundedArea(parseFloat(areaWithObstacles.toFixed(2)));
            }else {
                setRoundedArea(areaWithObstacles);
            }
        }
    }, [areaWithObstacles])

    useEffect(() => {
        handleObstacles(obstacles, setClipArea, setAreaWithObstacles, setClipsArray, setFloorM2, areaGFA,wallDistance);
    }, [obstacles, areaGFA, wallDistance])

    // useEffect(() => {
    //     if (areaGFA && areaNFA) {
    //         let wall = (areaGFA - areaNFA);
    //         console.log("wallDistance",wall)
    //         if(wall % 1 !== 0){
    //         setWallDistance(wall.toFixed(2));
    //         }else {
    //             setWallDistance(wall)
    //         }
    //     }
    // }, [areaGFA, areaNFA])

    const handleRoomClick = () => {
        onSelect(room.id);
    };
    const addNewRectangle = () => {
        const newRectangle = {
            type: 'rect',
            x: 120,
            y: 130,
            width: 100,
            height: 100,
            fill: '#fe9a9afc',
            opacity: 0.5,
            crop: true,
            clipName: `Hindring-${shapes.length + 1}`,
            id: `Hindring-${shapes.length + 1}`,
        };
        setShapes([...shapes, newRectangle]);
        setSelectedId(newRectangle.id)
        if (newRectangle.crop)
            selectObstacles(newRectangle, obstacles, setObstacles);
    };
    const handleDelete = (deletedId) => {
        const updatedShapes = shapes.filter((shape) => shape.id !== deletedId);
        setShapes(updatedShapes);
        if (selectedId === deletedId) {
            setSelectedId("");
        }
    };

    const saveDataToBackEnd = (e) => {
        let jsonData = {
            ...room,
            GFA: {...room.GFA, width: gfaWidth, height: gfaHeight},
            NFA: {...room.NFA, width: gfaWidth, height: gfaHeight},
            gap: {...room.gap, gapVal: gapVal},
            id: id,
            clips: shapes,
            gfaHeight: gfaHeight,
            gfaWidth: gfaWidth,
            areaGFA: areaGFA,
            areaNFA: areaNFA,
            wallDistance: wallDistance,
            roundedArea: roundedArea,
            clipArea: clipArea,
            clipsArray: clipsArray,
            floorM2:floorM2
        }
        // console.log("room data json", jsonData);
        postProject(jsonData)
            .then((res) => {
                let projID = (res.data.id)
                // saveProjectReference(projID,getFormattedDateTime(), location.pathname)
                let uri = '';
                if (stageRef)
                    uri = stageRef.current.toDataURL({pixelRatio: 3});
                if (uri.length > 0) {
                    fetch(uri).then(res => {
                        res.blob().then(blob => {
                            postImage(jsonData, blob).then(res => {
                                // console.log("image posted",res);
                                navigate('/room-properties/' + id)
                            })
                        })
                    })
                }

            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    const openModal = () => {
        setIsModalOpen(true);
        setModal(true);
        setGfaModal(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={"flex flex-col gap-2"}>
            {modal && room && (<GFAModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                setInputWidth={setInputWidth}
                setInputHeight={setInputHeight}
                setInputGapVal={setInputGapVal}
                initialWidth={gfaWidth}
                initialHeight={gfaHeight}
                initiialGap={gapVal}
            />)}
            {(selected && room && room.GFA && !libraryState) && (
                <Stage width={380} height={380} ref={stageRef}>
                    <Layer>
                        <Text
                            onDblClick={handleRoomClick}
                            x={gfaX}
                            y={10}
                            fontSize={15}
                            text={gfaWidth.toString() + " cm"}
                            onClick={openModal}
                            onTouchStart={openModal}
                        />
                        <Text
                            onDblClick={handleRoomClick}
                            x={10}
                            y={gfaY + 50}
                            fontSize={15}
                            text={gfaHeight.toString() + " cm"}
                            onClick={openModal}
                            onTouchStart={openModal}
                            rotation={-90}
                        />
                    </Layer>
                    <Layer>
                        <Rect
                            x={room.GFA.position.x}
                            y={room.GFA.position.y}
                            width={gfaWidthForDraw}
                            height={gfaHeightForDraw}
                            stroke={room.GFA.stroke}
                            strokeWidth={room.GFA.strokeWidth}
                            fill={room.GFA.fill}
                        />
                        <Rect
                            x={room.gap.position.x}
                            y={room.gap.position.y}
                            width={gapWidth}
                            height={gapHeight}
                            fill={room.gap.fill}
                        />
                        <Rect
                            key={room.NFA.id}
                            x={room.NFA.position.x + gapValCoordinates}
                            y={room.NFA.position.y + gapValCoordinates}
                            width={nfaWidthForDraw}
                            height={nfaHeightForDraw}
                            fill={room.NFA.fill}
                            shadowColor={room.NFA.shadowColor}
                            shadowBlur={room.NFA.shadowBlur}
                            shadowOpacity={room.NFA.shadowOpacity}
                        />
                    </Layer>
                    <Layer>
                        <Text
                            x={gfaX} y={gfaY} fontSize={30}
                            text={areaGFA.toString() + "㎡"}
                            opacity={0.4}
                        />
                        <Text
                            x={gfaX} y={gfaY + 30} fontSize={30}
                            text={roundedArea + "㎡"}
                            opacity={0.4}
                            align={"center"}
                        />
                    </Layer>
                    <Layer>
                        {shapes.map((shape, i) => {
                            if (shape.type === 'rect') {
                                return (
                                    <Rectangle
                                        key={shape.id}
                                        shapeProps={shape}
                                        onSelect={() => {
                                            setSelectedId(shape.id)
                                            if (shape.crop)
                                                selectObstacles(shape, obstacles, setObstacles);
                                        }}
                                        isSelected={shape.id === selectedId}
                                        onChange={(newAttrs) => {
                                            const newShapes = [...shapes];
                                            newShapes[i] = newAttrs;
                                            setShapes(newShapes);
                                            if (shape.crop)
                                                selectObstacles(newAttrs, obstacles, setObstacles);
                                        }}
                                        onDelete={() => {
                                            handleDelete(shape.id);
                                            deselectObstacles(shape, obstacles, setObstacles);
                                        }}
                                        gfaWidth={gfaWidthForDraw}
                                        gfaHeight={gfaHeightForDraw}
                                        gfaWForArea={gfaWidth}
                                        gfaHForArea={gfaHeight}
                                        pixelScale={pixelScale}
                                        room={room}
                                        clipsNew={shapes}
                                        gfaModal={gfaModal}
                                    />
                                );
                            }
                            return null;
                        })}
                    </Layer>
                </Stage>
            )}
            {(!libraryState && selected) && (<div className={""}>
                <div className={"flex justify-between "}>
                    <button className={"icon-button"} onClick={openModal}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M21.47 19V5C21.47 3 20.47 2 18.47 2H14.47C12.47 2 11.47 3 11.47 5V19C11.47 21 12.47 22 14.47 22H18.47C20.47 22 21.47 21 21.47 19Z"
                                stroke="#151B33" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M11.47 6H16.47" stroke="#151B33" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M11.47 18H15.47" stroke="#151B33" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M11.47 13.95L16.47 14" stroke="#151B33" strokeWidth="1.5"
                                  strokeLinecap="round"/>
                            <path d="M11.47 10H14.47" stroke="#151B33" strokeWidth="1.5" strokeLinecap="round"/>
                            <path
                                d="M5.49003 2C3.86003 2 2.53003 3.33 2.53003 4.95V17.91C2.53003 18.36 2.72003 19.04 2.95003 19.43L3.77003 20.79C4.71003 22.36 6.26003 22.36 7.20003 20.79L8.02003 19.43C8.25003 19.04 8.44003 18.36 8.44003 17.91V4.95C8.44003 3.33 7.11003 2 5.49003 2Z"
                                stroke="#151B33" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M8.44003 7H2.53003" stroke="#151B33" strokeWidth="1.5"
                                  strokeLinecap="round"/>
                        </svg>
                        Endre størrelse
                    </button>
                    <button className={"icon-button"} onClick={addNewRectangle}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M5.5 10C7.433 10 9 8.433 9 6.5C9 4.567 7.433 3 5.5 3C3.567 3 2 4.567 2 6.5C2 8.433 3.567 10 5.5 10Z"
                                stroke="#151B33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M5.5 21C7.433 21 9 19.433 9 17.5C9 15.567 7.433 14 5.5 14C3.567 14 2 15.567 2 17.5C2 19.433 3.567 21 5.5 21Z"
                                stroke="#151B33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M21.9999 6L8.6499 15.98" stroke="#151B33" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M21.9999 17.97L8.6499 7.98" stroke="#151B33" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Hindring
                    </button>
                </div>
                <div className={"flex gap-4 w-full"}>
                    <Link className={"standard-button"} onClick={(e) => {
                        e.preventDefault();
                        const doesAnyHistoryEntryExist = location.key !== "default";
                        if (doesAnyHistoryEntryExist) {
                            navigate(-1)
                        } else {
                            navigate('/')
                        }
                    }}
                    >Tilbake</Link>
                    <Link className={"primary-button"}
                          onClick={(e) => {
                              e.preventDefault();
                              saveDataToBackEnd(e);
                              saveProjectReference(id, projectName, location.pathname, areaGFA, clipArea, roundedArea, wallDistance, roomType)
                          }}
                    >Neste</Link>
                </div>
            </div>)}
        </div>
    );
}
