import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useLocation, useParams} from "react-router-dom";
import {postImage, postLogo, postProject} from "../utlils/services";
import {
    getBase64,
    getCompanyLogoAndNameRef, getFileFromBase64,
    getFormattedDateTime,
    saveCompanyNameAndLogo,
    saveProjectReference
} from "../utlils/functions";

export default function DetailsModal(props) {
    const [companyName, setCompanyName] = useState("");
    const [userName, setUserName] = useState("");
    const [projectName, setProjectName] = useState("");
    const [projectLocation, setProjectLocation] = useState("");
    const [editCompany, setEditCompany] = useState(true);
    const [editName, setEditName] = useState(true);
    const [logoURL, setLogoURL] = useState(null);
    const {id} = useParams();
    const location = useLocation();
    const [areaGfa, setAreaGfa] = useState();
    const [cableArea, setCableArea] = useState();
    const [clipArea, setClipArea] = useState();
    const [wallDistance, setWallDistance] = useState();
    const [roomType, setRoomType] = useState();
    const [file, setFile] = useState(null);
    const [imgBase64, setImgBase64] = useState("");
    const [logoSizeError, setLogoSizeError] = useState(false);
    const [companyLogoUrl, setCompanyLogoUrl] = useState("");


    useEffect(() => {
        if (props.room.details) {
            setCompanyName(props.room.details.companyName);
            setUserName(props.room.details.userName);
            setProjectName(props.room.details.projectName);
            setProjectLocation(props.room.details.projectLocation);
            setLogoURL(props.room.details.logo);
        }
        if (props.room) {
            setAreaGfa(props.room.areaGFA)
            setClipArea(props.room.clipArea)
            setCableArea(props.room.roundedArea)
            setWallDistance(props.room.wallDistance)
            setRoomType(props.room.roomProp.selectedRoom.name)
        }
        if(blob){
            const url = URL.createObjectURL(blob);
            setCompanyLogoUrl(url)
        }
    }, []);

    let COMPANY_NAME = localStorage.getItem('COMPANY_NAME');
    let USER_NAME = localStorage.getItem('USER_NAME');
    let blob = getFileFromBase64();

    const toggleEditCompany = () => {
        setEditCompany(!editCompany);
    };
    const toggleEditName = () => {
        setEditName(!editName);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 3 * 1024 * 1024; // 3MB
        if (file && file.size<maxSize) {
            setFile(file);
            setLogoSizeError(false);
            getBase64(file).then((base64)=>{
                setImgBase64(base64);
            })
        }else {
            setFile(null);
            setLogoSizeError(true);
            console.log("error:Logo upload failed! (File size exceeded 3MB)")
        }
    }


    const handleInputChangeCompany = (e) => {
        setCompanyName(e.target.value);
    };
    const handleInputChangeName = (e) => {
        setUserName(e.target.value);
    };
    const handleInputChangeProject = (e) => {
        setProjectName(e.target.value);
    };
    const handleInputChangeLocation = (e) => {
        setProjectLocation(e.target.value);
    };

    const handleSave = () => {
        let json = {
            ...props.room,
            details: {
                companyName: companyName?companyName:COMPANY_NAME,
                userName: userName?userName:USER_NAME,
                projectName: projectName,
                projectLocation: projectLocation,
                logo: logoURL
            }
        }
        postProject(json)

        if (file) {
            postLogo(id, file)
        }
        else if (blob) {
            postLogo(id, blob)
        }

        if (projectName){
            saveProjectReference(id, projectName, location.pathname, areaGfa, clipArea, cableArea, wallDistance, roomType)
        }
        if(companyName || file || userName){
            saveCompanyNameAndLogo(companyName,userName, file)
        }

        props.setCompanyName(companyName?companyName:COMPANY_NAME);
        props.setUserName(userName?userName:USER_NAME);
        props.setProjectName(projectName);
        props.setProjectLocation(projectLocation);
        props.onRequestClose();
    };
    const handleClose = () => {
        props.onRequestClose();
    }

    return (
        <div>
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                contentLabel="GFA Resize"
                className="Content"
                overlayClassName="Overlay"
            >
                <dialog
                    className={"dialog"}
                >
                    <a href="#" className={"close-button"}
                       onClick={handleClose}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke="#151B33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <g opacity="0.4">
                                <path d="M9.16992 14.8299L14.8299 9.16992" stroke="#151B33" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.8299 14.8299L9.16992 9.16992" stroke="#151B33" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </a>

                    {(editCompany) && <div className={"details"}>
                        <label htmlFor="company-name-plain" className={""}>
                            Firma:
                            <span className={"details-span"}> {COMPANY_NAME ? COMPANY_NAME : companyName}</span>
                        </label>
                        <button className={"rounded border border-gray-200 py-1 px-2 text-xs"} onClick={toggleEditCompany}>Legg inn</button>
                    </div>}
                    {(!editCompany) && <label htmlFor="companyName" className={"size-label"}>
                        Firma
                        <br/>
                        <input className={"details-input"}
                               type="text"
                               name={"companyName"}
                               id={"companyName"}
                               defaultValue={COMPANY_NAME ? COMPANY_NAME : companyName}
                               onChange={handleInputChangeCompany}
                        />
                    </label>}
                    {(editName) && <div className={"details"}>
                        <label htmlFor="company-name-plain" className={""}>
                            Ditt navn:
                            <span className={"details-span"}> {USER_NAME ? USER_NAME : userName}</span>
                        </label>
                        <button className={"rounded border border-gray-200 py-1 px-2 text-xs"} onClick={toggleEditName}>Legg inn</button>
                    </div>}
                    {(!editName) && <label htmlFor="userName" className={"size-label"}>
                        Ditt navn
                        <br/>
                        <input className={"details-input"}
                               type="text"
                               name={"userName"}
                               id={"userName"}
                               defaultValue={USER_NAME ? USER_NAME : userName}
                               onChange={handleInputChangeName}
                        />
                    </label>}
                    <label htmlFor="projectName" className={"size-label"}>
                        Prosjekt / Kunde
                        <br/>
                        <input className={"details-input"}
                               type="text"
                               name={"projectName"}
                               id={"projectName"}
                               value={projectName}
                               onChange={handleInputChangeProject}
                        />
                    </label>
                    <label htmlFor="projectLocation" className={"size-label"}>
                        Adresse
                        <br/>
                        <input className={"details-input"}
                               type="text"
                               name={"projectLocation"}
                               id={"projectLocation"}
                               value={projectLocation}
                               onChange={handleInputChangeLocation}
                        />
                    </label>
                    <div className={"w-full flex flex-col gap-2"}>
                            <span className={"size-label"}>Firma logo:</span>
                        <div className={"flex flex-col w-28"}>
                            <label htmlFor="projectLogo" className={"border-2 rounded-md bg-gray-200 p-2 cursor-pointer"}>Choose File</label>
                            <br/>
                            <input className={"hidden w-4"}
                                   type="file"
                                   name={"projectLogo"}
                                   id={"projectLogo"}
                                   onChange={handleFileChange}
                            />
                            {(imgBase64||companyLogoUrl)&&(<img src={imgBase64 ? imgBase64 : companyLogoUrl} alt="company_logo" width={150}
                                  height={250}/>)}
                        </div>
                        <span className={"text-red-800"}>{logoSizeError && "Error: Logo upload failed! (File size exceeded 3MB)"}</span>
                    </div>

                    <div className={"flex"}>
                        <button className={"ov-button"}
                                onClick={handleSave}>OK
                        </button>
                    </div>
                </dialog>
            </Modal>
        </div>
    );
};

