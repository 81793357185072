import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {getPdf, getProject, postLogo, postProject} from "../utlils/services";
import DetailsModal from "./DetailsModal";
import {getFileFromBase64} from "../utlils/functions";

export default function Results() {
    const location = useLocation()
    const imageUri = location.state?.imageUri || 'error';
    const navigate = useNavigate();
    const [room, setRoom] = useState(null)
    const {id} = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [userName, setUserName] = useState("");
    const [projectName, setProjectName] = useState("");
    const [projectLocation, setProjectLocation] = useState("");
    const [roomDetails, setRoomDetails] = useState();


    useEffect(() => {
        getProject(id).then(response => {
            if (response.data)
                setRoom(response.data);
            setRoomDetails(response.data.details)
        })
    }, [id]);

    useEffect(() => {
        if (roomDetails) {
            setCompanyName(roomDetails.companyName)
            setUserName(roomDetails.userName)
            setProjectName(roomDetails.projectName)
            setProjectLocation(roomDetails.projectLocation)
        }
    }, [room])

    // let blob = getFileFromBase64();
    // const handlePDFDownload = async () => {
    //     try {
    //         let json = {
    //             ...room,
    //             details: {
    //                 companyName: COMPANY_NAME,
    //                 userName: USER_NAME,
    //                 projectName: projectName?projectName:"",
    //                 projectLocation: projectLocation?projectLocation:"",
    //                 logo: ""
    //             }
    //         };
    //
    //         await postProject(json);
    //         if(blob)
    //         await postLogo(id, blob)
    //
    //         const url = `${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}.pdf`;
    //         window.open(url, '_blank');
    //     } catch (error) {
    //         console.error('Error posting project:', error);
    //     }
    // };

    const openModal = () => {
        setIsModalOpen(true);
        setModal(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    if (room)
        return (
            <div className={"container"}>
                {modal && (<DetailsModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    room={room}
                    setCompanyName={setCompanyName}
                    setUserName={setUserName}
                    setProjectName={setProjectName}
                    setProjectLocation={setProjectLocation}
                />)}
                <div>
                    <img
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/calculator/${id}`)
                        }}
                        src={`${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}/floorplan.png`}
                        alt="image"/>
                </div>

                {(companyName || userName || projectName || projectLocation) && (<table className={"size-table"}>
                    <tbody>
                    <tr>
                        <td colSpan="3" className={"info-header"}>Prosjekt</td>
                    </tr>
                    {companyName && <tr>
                        <td>Firmanavn</td>
                        <td className={"num"}><span>{companyName}</span></td>
                    </tr>}
                    {userName && <tr>
                        <td>Elektriker</td>
                        <td className={"num"}><span>{userName}</span></td>
                    </tr>}
                    {projectName && <tr>
                        <td>Kunde</td>
                        <td className={"num"}><span>{projectName}</span></td>
                    </tr>}
                    {projectLocation && <tr>
                        <td>Adresse</td>
                        <td className={"num"}><span>{projectLocation}</span></td>
                    </tr>}
                    </tbody>
                </table>)}


                <table className={"size-table"}>
                    <tbody>
                    <tr>
                        <td colSpan="3" className={"info-header"}>Rom</td>
                    </tr>
                    {(room.calculatorType === "concrete" || room.calculatorType === "lamiflex") && (<tr>
                        <td>Romtype</td>
                        <td className={"num"}><span>{room.roomProp.selectedRoom.name}</span></td>
                    </tr>)}
                    {(room.calculatorType === "concrete" || room.calculatorType === "heating-mat") && (<tr>
                        <td>Overgulv</td>
                        <td className={"num"}><span>{room.roomProp.floor.name}</span></td>
                    </tr>)}
                    <tr>
                        <td>Ønsket effekt</td>
                        <td className={room.roomProp.warningState ? "num flex justify-between" : "num"}>{room.roomProp.warningState && (
                            <span className={'text-yellow-600'}>PS: Utenfor vår anbefaling</span>)}<span>{room.roomProp.power}</span></td>
                    </tr>
                    </tbody>
                </table>

                <table className={"size-table"}>
                    <tbody>
                    <tr>
                        <td colSpan="3" className={"info-header"}>Areal</td>
                    </tr>
                    <tr>
                        <td>Brutto areal</td>
                        <td className={"num"}><span>{room.areaGFA}</span></td>
                        <td className={"num"}>㎡</td>
                    </tr>
                    {room.clipsArray && room.clipsArray.map((clip, index) => {
                        return (
                            <tr key={index} className={"text-red-500"}>
                                <td>{clip.clipName}</td>
                                <td className={"num"}><span>{clip.clipArea}</span></td>
                                <td className={"num"}>㎡</td>
                            </tr>
                        );
                    })}
                    {(room.calculatorType === "concrete" || room.calculatorType === "heating-mat") &&
                        <tr className={"text-red-500"}>
                            <td>Areal avstand vegg ({room.gap.gapVal})</td>
                            <td className={"num"}><span>{room.wallDistance}</span></td>
                            <td className={"num"}>㎡</td>
                        </tr>}
                    <tr>
                        <td>Effektivt areal</td>
                        <td className={"num "}><span
                            className={" "}>{(room.calculatorType === "lamiflex") ? room.roomProp.lamiflexCableArea : room.roundedArea}</span>
                        </td>
                        <td className={"num "}><span className={""}>㎡</span></td>
                    </tr>
                    {room.calculatorType === "concrete" && (<tr>
                        <td colSpan="3" className={"info-header"}>Kabel</td>
                    </tr>)}
                    {room.calculatorType === "concert" && (<tr>
                        <td>Kabellengde totalt</td>
                        <td className={"num "}><span className={""}>{room.calculations.calcCableLength}</span>
                        </td>
                        <td className={"num "}><span className={""}>m</span></td>
                    </tr>)}
                    {room.calculatorType === "concrete" && (<tr className={(room.calculations.cableDistance && room.calculations.cableDistance > 13) ? "text-yellow-500": ""}>
                        <td>Senteravstand (c-c)</td>
                        <td className={"num "}><span>{room.calculations.cableDistance}</span></td>
                        <td className={"num "}><span>cm</span></td>
                    </tr>)}
                    {(room.calculatorType === "lamiflex") && <tr>
                        <td colSpan="3" className={"info-header"}>Lamiflex</td>
                    </tr>}
                    {(room.calculatorType === "lamiflex") && <tr>
                        <td>Lamiflex gulv</td>
                        <td className={"num "}><span
                            className={" "}>{room.roomProp.lamiflexArea}</span>
                        </td>
                        <td className={"num "}><span className={""}>㎡</span></td>
                    </tr>}
                    <tr>
                        <td colSpan="3" className={"info-header"}>Effekt</td>
                    </tr>
                    {room.calculatorType === "concrete" && (<tr>
                        <td>Effekt per m kabel</td>
                        <td className={"num "}><span className={""}>{room.calculations.wattPerMCable}</span>
                        </td>
                        <td className={"num "}><span className={""}>W</span></td>
                    </tr>)}
                    <tr>
                        <td>Reell effekt</td>
                        <td className={"num "}><span className={" "}>{room.calculations.wattPerM2Cable}</span>
                        </td>
                        <td className={"num "}><span className={""}>W / ㎡</span></td>
                    </tr>
                    <tr>
                        <td>Total effekt</td>
                        <td className={"num "}><span className={" "}>{room.calculations.totalWatts}</span></td>
                        <td className={"num "}><span className={""}>W </span></td>
                    </tr>
                    </tbody>
                </table>
                <div className={"recommendations"}>
                    <table className={"recommendation-table"}>
                        <thead>
                        <tr>
                            <td colSpan={2} className={"text-sm text-gray-700"}>
                                Du trenger følgende kabeltyper
                            </td>
                        </tr>
                        </thead>
                        {room.calculations.cables && room.calculations.cables.map((c, index) => (
                            <tbody key={index}>
                            <tr>
                                <td>{c.amount} stk</td>
                                {/*{(room.roomProp.selectedRoom.cableType.find((c)=>c.selected).value === "4" || room.roomProp.cableType.value === "5") ?*/}
                                {(room.roomProp.cableType.value === "4" || room.roomProp.cableType.value === "5") ?
                                    <td className={"font-semibold"}> {c.cable.name} {c.cable.length}m</td> :
                                    <td className={"font-semibold"}> {c.cable.name}</td>}
                            </tr>
                            <tr>
                                <td>El.nr.</td>
                                <td> {c.cable.elnr} </td>
                            </tr>
                            </tbody>
                        ))}
                        {room.calculations.lamiFlex && room.calculations.lamiFlex.map((lamiflex, index) => (
                            <tbody key={index}>
                            <tr>
                                <td>{lamiflex.amount} stk</td>
                                <td className={"font-semibold"}>{lamiflex.item.name} </td>
                            </tr>
                            <tr>
                                <td>El.nr.</td>
                                <td>{lamiflex.item.elnr}</td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
                <div className={"flex gap-4 w-full"}>
                    <Link className={"text-blue-600 pt-12"}
                          onClick={(e) => {
                              e.preventDefault();
                              const doesAnyHistoryEntryExist = location.key !== "default";
                              if (doesAnyHistoryEntryExist) {
                                  navigate(-1)
                              } else {
                                  navigate('/room-properties/' + id)
                              }
                          }
                    }
                    >Tilbake</Link>
                        <button
                            className={"standard-button mt-4"}
                            onClick={(e) => {
                            e.preventDefault();
                            openModal();
                        }}>Adresse
                        </button>
                    <a className={"primary-button"}
                       href={`${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}.pdf`} target={"_blank"}
                    >Last ned PDF</a>
                </div>
            </div>
        );
}