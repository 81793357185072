import React, {useEffect, useRef, useState} from "react";
import {
    calculateGFAArea,
    calculateNFAArea,
} from "../utlils/functions"
import data from "../data/roomData.json";
import {useLocation, useParams} from "react-router-dom";
import Room from "./Room";
import {getProject} from "../utlils/services";

export default function NewCalculator() {
    const [rooms, setRooms] = useState(data.rooms);
    const [initialRoom, setInitialRoom] = useState();
    const [gfaWidth, setGfaWidth] = useState(0);
    const [gfaHeight, setGfaHeight] = useState(0);
    const [nfaWidth, setNfaWidth] = useState(0);
    const [nfaHeight, setNfaHeight] = useState(0);
    const [areaGFA, setAreaGFA] = useState(0);
    const [areaNFA, setAreaNFA] = useState(0);
    const {id} = useParams();
    const location = useLocation();
    const roomData = location.state?.roomData || 0;

    const [update, setUpdate] = useState(new Date().getMilliseconds());
    // console.log("roomData in Calc",initialRoom)

    useEffect(() => {
        if (!initialRoom)
            rooms.map((room) => {
                if (room.id === 5) {
                    let r = {...room, id: id}
                    setInitialRoom(r)
                }
            })
    }, [rooms])

    useEffect(() => {
        getRoomData();
    }, [id]);

    const getRoomData = () => {
        if (id) {
            getProject(id)
                .then((res) => {
                    if (res) {
                        let roomData = res.data
                        if (roomData) {
                            setInitialRoom(roomData);
                            setUpdate(new Date().getMilliseconds());
                        }
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
    }
    useEffect(() => {
        calculateGFAArea(gfaHeight, gfaWidth, setAreaGFA);
        calculateNFAArea(nfaHeight, nfaWidth, setAreaNFA);
    }, [gfaHeight, gfaWidth, nfaWidth, nfaHeight])

    if (initialRoom)
        return (
            <div className={""}>
                {(initialRoom) && (<div className={"mt-8"}>
                        <Room
                            key={update}
                            room={initialRoom}
                            selected={true}
                            setGfaWidthForArea={setGfaWidth}
                            setGfaHeightForArea={setGfaHeight}
                            setNfaHeightForArea={setNfaHeight}
                            setNfaWidthForArea={setNfaWidth}
                            areaGFA={areaGFA}
                            areaNFA={areaNFA}
                        />
                    </div>
                )}
            </div>
        );
}