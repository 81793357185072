export const convertValuesToPixels = (gfaWidth, gfaHeight, setPixelScale) => {
    if (gfaHeight > gfaWidth) {
        setPixelScale(gfaHeight / 320)
    } else {
        setPixelScale(gfaWidth / 320)
    }
}
export const calculateGFAArea = (gfaHeight, gfaWidth, setAreaGFA) => {
    const area = (gfaHeight * gfaWidth) / 10000;
    if (area % 1 !== 0) {
        setAreaGFA(area.toFixed(2))
    } else {
        setAreaGFA(area);
    }
}
export const calculateNFAArea = (nfaHForArea, nfaWForArea, setAreaNFA) => {
    const area = (nfaHForArea * nfaWForArea) / 10000;
    setAreaNFA(area);
}
export const selectObstacles = (obstacle, obstacles, setObstacles) => {
    let newObstacle = obstacles.filter(ob => ob.id !== obstacle.id);
    newObstacle.push(obstacle);
    setObstacles(newObstacle);
}

export const deselectObstacles = (obstacle, obstacles, setObstacles) => {
    let newObstacle = obstacles.filter(ob => ob.id !== obstacle.id)
    setObstacles(newObstacle);
}

export function calculateObstacleArea(obstacle) {
    if (obstacle.type === 'circle') {
        let areaCircle = 3.14 * (obstacle.radius * obstacle.radius);
        // console.log("areaCircle", areaCircle);
        return areaCircle;
    } else {
        let areaRect = (obstacle.height * obstacle.width / 10000);
        // console.log("areaRect ", areaRect);
        return areaRect;
    }
}

export const handleObstacles = (obstacles, setClipArea, setAreaWithObstacles, setClipsArray, setFloorM2, areaGFA, wallDistance) => {
    if (obstacles.length > 0) {
        let obsArea = 0;
        let obsArray = [];
        let rectArea = 0;
        let rectAreaRounded = 0;
        let obs = {};
        obstacles.forEach(obstacle => {
            obsArea = obsArea + calculateObstacleArea(obstacle);
            rectArea = calculateObstacleArea(obstacle);
            if (rectArea % 1 !== 0) {
                rectAreaRounded = rectArea.toFixed(2)
            } else {
                rectAreaRounded = rectArea;
            }
            obs = {clipName: obstacle.clipName, clipArea: rectAreaRounded}
            obsArray.push(obs);
        });
        setClipArea(obsArea);
        setClipsArray(obsArray);
        setAreaWithObstacles(areaGFA - (obsArea + wallDistance));
        setFloorM2(areaGFA - obsArea);
    } else {
        setAreaWithObstacles(areaGFA - wallDistance);
        setClipArea(0);
        setClipsArray([]);
        setFloorM2(areaGFA);
    }
};

export function calculateWallDistanceArea(gfaWidth, gfaHeight, gapVal) {
    let wallDistanceArea = 0;
    wallDistanceArea = ((2 * gfaWidth + 2 * gfaHeight - 4 * gapVal) * gapVal) / 10000
    if (wallDistanceArea % 1 !== 0) {
        return wallDistanceArea.toFixed(2);
    } else {
        return wallDistanceArea;
    }
}

export const handleExport = (stageRef, setImageUri) => {
    if (stageRef) {
        const uri = stageRef.current.toDataURL({pixelRatio: 3});
        // downloadURI(uri, 'stage.png');
        setImageUri(uri);
    }
};
const downloadURI = (uri, name) => {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


const MY_PROJECTS_KEY = "OEGLAND_PROJECTS"
const COMPANY_NAME = "COMPANY_NAME"
const USER_NAME = "USER_NAME"
const COMPANY_LOGO = "COMPANY_LOGO"

export function saveProjectReference(projectId, projectName, path, areaGfa, clipArea, cableArea, wallDistance, roomType) {
    let myProjects = getMyProjectsRef();
    let existingProject = myProjects.find(value => value.id===projectId);

    if (existingProject){
        if (projectName===null || projectName.trim()==="")
            projectName = existingProject.name
        if (roomType===null || roomType.trim()==="")
            roomType = existingProject.roomType
    }

    myProjects = myProjects.filter(p => p.id !== projectId);

    myProjects.push({
        id: projectId,
        name: projectName,
        link: path,
        areaGfa: areaGfa,
        clipArea: clipArea,
        cableArea: cableArea,
        wallDistance: wallDistance,
        roomType: roomType
    })
    localStorage.setItem(MY_PROJECTS_KEY, JSON.stringify(myProjects));

}

export function saveCompanyNameAndLogo(companyName, userName, file) {
    if (companyName) {
        localStorage.setItem(COMPANY_NAME, companyName)
    }
    if (userName) {
        localStorage.setItem(USER_NAME, userName)
    }
    if (file) {
        getBase64(file).then((base64) => {
            localStorage.setItem(COMPANY_LOGO, base64);
        });
    }
}

export function getFileFromBase64() {
    const base64String = localStorage.getItem('COMPANY_LOGO');
    if (base64String) {
        const byteString = atob(base64String.split(',')[1]);
        const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], {type: mimeString});
        return blob;
    }

}

export function getCompanyLogoAndNameRef() {
    let s = localStorage.getItem(COMPANY_LOGO);
    if (s)
        return JSON.parse(s);
    else return [];
}


export function getMyProjectsRef() {
    let s = localStorage.getItem(MY_PROJECTS_KEY);
    if (s)
        return JSON.parse(s);
    else return [];
}

export function getFormattedDateTime() {
    const now = new Date();
    return now.getFullYear() + '-' +
        ('0' + (now.getMonth() + 1)).slice(-2) + '-' +
        ('0' + now.getDate()).slice(-2) + ' ' +
        ('0' + now.getHours()).slice(-2) + ':' +
        ('0' + now.getMinutes()).slice(-2) + ':' +
        ('0' + now.getSeconds()).slice(-2);
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
}