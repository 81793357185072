import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';

export default function GFAModal(props) {
    const [userInputWidth, setUserInputWidth] = useState(props.initialWidth);
    const [userInputHeight, setUserInputHeight] = useState(props.initialHeight);
    const [userInputGap, setUserInputGap] = useState(props.initiialGap);
    const [editGap, setEditGap] = useState(true);

    const toggleGap = () => {
        setEditGap(!editGap);
    }
    const handleInputChangeWidth = (e) => {
        setUserInputWidth(e.target.value);
    };

    const handleInputChangeHeight = (e) => {
        setUserInputHeight(e.target.value);
    };
    const handleInputChangeGap = (e) => {
        setUserInputGap(e.target.value);
    };

    const handleSave = () => {
        const width = parseFloat(userInputWidth);
        const height = parseFloat(userInputHeight);
        const gap = parseFloat(userInputGap);
        props.setInputWidth(width);
        props.setInputHeight(height);
        props.setInputGapVal(gap);
        props.onRequestClose();
    };
    const handleClose = () => {
        props.onRequestClose();
    }

    return (
        <div>
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                contentLabel="GFA Resize"
                className="Content"
                overlayClassName="Overlay"
            >
                <dialog
                    className={"dialog"}
                >
                    <a href="#" className={"close-button"}
                       onClick={handleClose}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke="#151B33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <g opacity="0.4">
                                <path d="M9.16992 14.8299L14.8299 9.16992" stroke="#151B33" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.8299 14.8299L9.16992 9.16992" stroke="#151B33" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </a>

                    <label htmlFor="lengde" className={"size-label"}>
                        Bredde
                        <br/>
                        <div className={"relative"}>
                            <input className={"size-input"}
                                   type="tel"
                                   name={"bredde"}
                                   id={"bredde"}
                                   value={userInputWidth}
                                   onChange={handleInputChangeWidth}
                            />
                            <div className="size-label-span-container">
                                <span className="size-label-span">cm</span>
                            </div>
                        </div>
                    </label>
                    <label htmlFor="lengde" className={"size-label"}>
                        Lengde
                        <br/>
                        <div className={"relative"}>
                        <input className={"size-input"}
                               type="tel"
                               name={"lengde"}
                               id={"lengde"}
                               value={userInputHeight}
                               onChange={handleInputChangeHeight}
                        />
                            <div className="size-label-span-container">
                                <span className="size-label-span">cm</span>
                            </div>
                        </div>
                    </label>
                    {!editGap && <label htmlFor="gap" className={"size-label"}>
                        Avstand vegg
                        <br/>
                        <input className={"size-input"}
                               type="tel"
                               name={"gap"}
                               id={"gap"}
                               value={userInputGap}
                               onChange={handleInputChangeGap}
                        />
                    </label>}
                    {editGap && (<div className={"flex gap-5 text-gray-500"}>
                        <span>Avstand vegg er standard: {userInputGap} cm</span>
                        <button className={"rounded border border-gray-200 py-1 px-2 text-xs"} onClick={toggleGap}>Endre avstand
                        </button>
                    </div>)}
                    <p className={"text-gray-500"}>Alle mål må oppgis i centimeter</p>
                    <div className={"flex"}>
                        <button className={"ov-button"}
                                onClick={handleSave}>OK
                        </button>
                    </div>
                </dialog>
            </Modal>
        </div>
    );
};

