import React, {useEffect, useRef, useState} from "react";
import {Circle, Layer, Line, Rect, Shape, Stage, Text, Transformer, Image, Path, Group} from "react-konva";
import {Html} from 'react-konva-utils';
import ClipsModal from "./ClipsModal";

const Rectangle = ({
                       shapeProps,
                       isSelected,
                       onSelect,
                       onChange,
                       onDelete,
                       gfaWidth,
                       gfaHeight,
                       gfaWForArea,
                       gfaHForArea,
                       pixelScale,
                       room,
                       clipsNew,
                       gfaModal
                   }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initialShapeWidth, setInitialShapeWidth] = useState(0);
    const [initialShapeHeight, setInitialShapeHeight] = useState(0);
    const [userInputWidth, setUserInputWidth] = useState(0);
    const [userInputHeight, setUserInputHeight] = useState(0);
    const [selected, setSelected] = useState(isSelected);
    const [modal, setModal] = useState(false);
    const [shapePropsForDraw, setShapePropsForDraw] = useState(shapeProps);
    const [clipName, setClipName] = useState("");

    const shapeRef = useRef();
    const trRef = useRef();
    const textRef = useRef();


    // console.log("clip name", clipName);
    // console.log("custom", customClipName);
    // console.log("shapeProps", shapeProps);
    // console.log("clips new",clipsNew)

    useEffect(() => {
        if (shapeProps && pixelScale) {
            let newW = shapeProps.width / pixelScale
            let newH = shapeProps.height / pixelScale
            setShapePropsForDraw(prevShapeProps => ({
                ...prevShapeProps,
                width: newW,
                height: newH
            }));
        }
    }, [pixelScale, shapeProps])


    const [position, setPosition] = useState({
        x: shapeProps.x,
        y: shapeProps.y,
        width: shapeProps.width,
        height: shapeProps.height,
    });
    const [history, setHistory] = useState([
        {
            x: shapeProps.x,
            y: shapeProps.y,
        },
    ]);
    const [historyStep, setHistoryStep] = useState(0);
    const [positionH, setPositionH] = useState(history[0]);

    const handleUndo = () => {
        if (historyStep === 0) {
            return;
        }
        const previous = history[historyStep - 1];
        setHistoryStep(historyStep - 1);
        setPositionH(previous);
    };

    const handleRedo = () => {
        if (historyStep === history.length - 1) {
            return;
        }
        const next = history[historyStep + 1];
        setHistoryStep(historyStep + 1);
        setPositionH(next);
    };

    const handleDragEnd = (e) => {
        const pos = {
            x: e.target.x(),
            y: e.target.y(),
        };
        setHistory((prevHistory) => [...prevHistory.slice(0, historyStep + 1), pos]);
        setHistoryStep(historyStep + 1);
        setPositionH(pos);
    };

    useEffect(() => {
        if (shapeProps.id >= `Hindring-${2}` && room.roomClips === true) {
            if (userInputWidth > 0 || userInputHeight > 0) {
                setInitialShapeWidth(userInputWidth);
                setInitialShapeHeight(userInputHeight);
                handleApplySize();
            } else {
                setInitialShapeWidth(100);
                setInitialShapeHeight(100);
                setClipName(shapeProps.clipName)
            }
        }
    }, [userInputHeight, userInputWidth, clipName])

    useEffect(() => {
        if (room && room.roomClips === true) {
            room.clips.map((shape) => {
                if (shape.type === "rect" && shape.id === shapeProps.id) {
                    if (userInputWidth > 0 || userInputHeight > 0) {
                        setInitialShapeWidth(userInputWidth);
                        setInitialShapeHeight(userInputHeight);
                        handleApplySize();
                    } else {
                        setInitialShapeWidth(shape.width);
                        setInitialShapeHeight(shape.height);
                        setClipName(shape.clipName);
                    }
                }
            })
        } else if (room.roomClips === false && clipsNew) {
            clipsNew.map((shape) => {
                if (shape.type === "rect" && shape.id === shapeProps.id) {
                    if (userInputWidth > 0 || userInputHeight > 0) {
                        setInitialShapeWidth(userInputWidth);
                        setInitialShapeHeight(userInputHeight);
                        handleApplySize();
                    } else {
                        setInitialShapeWidth(shape.width);
                        setInitialShapeHeight(shape.height);
                        setClipName(shape.clipName);
                    }
                }
            })
        }
    }, [userInputWidth, userInputHeight, clipName])

    useEffect(() => {
        setSelected(isSelected);
    }, [isSelected])

    useEffect(() => {
        if (selected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selected]);

    useEffect(() => {
        setPosition({
            x: shapeProps.x,
            y: shapeProps.y,
            width: shapeProps.width,
            height: shapeProps.height,
        });
    }, [shapeProps]);
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setPosition((prevPosition) => ({
            ...prevPosition,
            [name]: parseFloat(value) || 0,
        }));
    };
    const handleApplySize = () => {
        const restrictedWidth = Math.max(5, Math.min(gfaWForArea, userInputWidth));
        const restrictedHeight = Math.max(5, Math.min(gfaHForArea, userInputHeight));
        onChange({
            ...shapeProps,
            x: positionH.x,
            y: positionH.y,
            width: restrictedWidth,
            height: restrictedHeight,
            clipName: clipName
        });
    };
    const handleRectDragEnd = (e) => {
        const rectPos = {
            x: e.target.x(),
            y: e.target.y(),
        };
        const rectSize = {
            width: Math.max(5, userInputWidth),
            height: Math.max(5, userInputHeight),
        };
        setPosition((prevPosition) => ({
            ...prevPosition,
            x: rectPos.x,
            y: rectPos.y,
        }));
        handleApplySize();
        handleDragEnd(e);
        onChange({
            ...shapeProps,
            x: rectPos.x,
            y: rectPos.y,
        });
        applyBoundaryRestriction(shapeRef.current, rectPos, rectSize);
    };
    const applyBoundaryRestriction = (node, rectPos) => {
        let pos = node.attrs;
        let rectX = 33;
        let rectY = 33;
        let rectWidth = gfaWidth - 6;
        let rectHeight = gfaHeight - 6;

        let transformedWidth = Math.max(5, node.width() * node.scaleX());
        let transformedHeight = Math.max(5, node.height() * node.scaleY());

        if (
            pos.x < rectX ||
            pos.y < rectY ||
            pos.x + transformedWidth > rectX + rectWidth ||
            pos.y + transformedHeight > rectY + rectHeight
        ) {
            node.x(Math.max(rectX, Math.min(rectX + rectWidth - transformedWidth, pos.x)));
            node.y(Math.max(rectY, Math.min(rectY + rectHeight - transformedHeight, pos.y)));
        }
    };
    const handleDragMove = (node) => {
        applyBoundaryRestriction(node, node.attrs);
    };
    const openModal = () => {
        setIsModalOpen(true);
        setModal(true);

    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <React.Fragment>
            <Rect
                onClick={() => {
                    onSelect()
                    openModal()
                }}
                onTap={() => {
                    onSelect()
                    openModal()
                }}
                ref={shapeRef}
                {...shapePropsForDraw}
                x={positionH.x}
                y={positionH.y}
                draggable
                onDragEnd={(e) => {
                    setPosition((prevPosition) => ({
                        ...prevPosition,
                        x: e.target.x(),
                        y: e.target.y(),

                    }));
                    handleApplySize();
                    handleRectDragEnd(e);
                }}
                onDragMove={() => {
                    handleDragMove(shapeRef.current);
                }}
            />

            {selected && (
                <Transformer
                    visible={false}
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
            {isModalOpen && <Html>
                <div className={"!relative"}>
                    <ClipsModal
                        id={shapeProps.id}
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        handleInputChange={handleInputChange}
                        delete={onDelete}
                        initialWidth={initialShapeWidth}
                        initialHeight={initialShapeHeight}
                        userInputWidth={setUserInputWidth}
                        userInputHeight={setUserInputHeight}
                        clipName={clipName}
                        setCustomClipName={setClipName}
                    />
                </div>
            </Html>}
            {(selected && !isModalOpen && !gfaModal) && <Html>
                <div className={"flex gap-x-14 text-gray-400"}>
                    <div
                        className={"flex rounded-md border border-gray-300 bg-white shadow-md items-center text-gray-600"}>
                        <button className={"px-2"}>
                            <svg width="22" height="18" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" onClick={handleUndo}>
                                <path
                                    d="M7.12988 18.3101H15.1299C17.8899 18.3101 20.1299 16.0701 20.1299 13.3101C20.1299 10.5501 17.8899 8.31006 15.1299 8.31006H4.12988"
                                    stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path d="M6.43012 10.8099L3.87012 8.24994L6.43012 5.68994" stroke="#292D32"
                                      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        <button className={"px-2"}>
                            <svg width="22" height="18" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" onClick={handleRedo}>
                                <path
                                    d="M16.8701 18.3101H8.87012C6.11012 18.3101 3.87012 16.0701 3.87012 13.3101C3.87012 10.5501 6.11012 8.31006 8.87012 8.31006H19.8701"
                                    stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path d="M17.5701 10.8099L20.1301 8.24994L17.5701 5.68994" stroke="#292D32"
                                      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </Html>}
        </React.Fragment>
    );
};
export default Rectangle;
