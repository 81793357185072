import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import roomProperties from "../data/roomProperties.json";
import {getCalculations, getCalculationsForLamiflex, getProject, postProject} from "../utlils/services";
import {saveProjectReference} from "../utlils/functions";

export default function RoomProperties() {
    const [roomProps, setRoomProps] = useState(roomProperties.roomTypes.concrete);
    const [selectedRoomType, setSelectedRoomType] = useState();
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [cableType, setCableType] = useState();
    const [power, setPower] = useState(0);
    const [floor, setFloor] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const imageUri = location.state?.imageUri || 'error';
    const [room, setRoom] = useState(null)
    const {id} = useParams();
    const [calculatorType, setCalculatorType] = useState("concrete");
    const [lamiflexPower, setLamiflexPower] = useState();
    const [lamiflexArea, setLamiflexArea] = useState(0);
    const [lamiflexClips, setLamiflexClips] = useState([]);
    const [lamiflexClipsArea, setLamiflexClipsArea] = useState(0);
    const [lamiflexCableArea, setLamiflexCableArea] = useState(0);

    const [floorChange, setFloorChange] = useState(false);
    const [cableChange, setCableChange] = useState(false);

    const [lamiRoom, setLamiRoom] = useState(true);
    const [voltageWarnRed, setVoltageWarnRed] = useState(false);
    const [voltageWarnRedBelow, setVoltageWarnRedBelow] = useState(false);
    const [voltageWarnYellow, setVoltageWarnYellow] = useState(false);
    const [traditionalConcFloor, setTraditionalConcFloor] = useState(true);
    const [concreteType, setConcreteType] = useState("traditional");
    const [concreteTypeChange, setConcreteTypeChange] = useState(false);
    const [lamiflexPowerChange, setLamiFlexPowerChange] = useState(false);
    const [userGivenPower, setUserGivenPower] = useState(0);
    const [powerChange, setPowerChange] = useState(false);
    const [maxWattPerM2, setMaxWattPerM2] = useState(0);
    const [underFloor, setUnderFloor] = useState("non-flammable");
    const [concreteChange, setConcreteChange] = useState(false);
    const [underFloorChange, setUnderFloorChange] = useState(false);
    const [roomTypeChange, setRoomTypeChange] = useState(false);
    let projectName = "";


    useEffect(() => {
        if (calculatorType === "lamiflex") {
            setRoomProps(roomProperties.roomTypes.lamiflex)
            setLamiflexArea(room.areaGFA)
            // setLamiRoom(false)
            // if(!lamiRoom){
            // setSelectedRoomType("living_room")
            // }

        } else if (calculatorType === "heating-mat") {
            setRoomProps(roomProperties.roomTypes.heatingmatNew);
            setUnderFloor("non-flammable")
            setUnderFloorChange(false);
            setConcreteType("traditional");

        } else {
            setSelectedRoomType("living_room")
            setRoomProps(roomProperties.roomTypes.concrete)
            setVoltageWarnRed(false);
            setVoltageWarnRedBelow(false);
            setVoltageWarnYellow(false);
            setPowerChange(false);
            setUnderFloor("non-flammable")
            setUnderFloorChange(false);
            setConcreteTypeChange(false);

        }
        if (room && room.roomProp) {
            if (calculatorType !== room.calculatorType) {
                setSelectedRoomType("living_room")
            }
        }
    }, [calculatorType])


    useEffect(() => {
        if (calculatorType === "concrete") {
            if (floor.value === "wood") {
                if(power<=60){
                setMaxWattPerM2(60);
                }else if(power>80){
                    setMaxWattPerM2(80);
                }else {
                    setMaxWattPerM2(power)
                }
            } else if (floor.value === "linoleum") {
                if(power<=100){
                setMaxWattPerM2(100);
                }else if(power>140){
                    setMaxWattPerM2(140);
                }else {
                    setMaxWattPerM2(power)
                }
            } else if (floor.value === "tiles" && concreteType === "low-profile") {
                if(power<=140){
                setMaxWattPerM2(140);
                }else if(power>170){
                    setMaxWattPerM2(170)
                }else {
                    setMaxWattPerM2(power)
                }
            } else if (floor.value === "tiles" && concreteType === "traditional") {
                if(power<=150){
                setMaxWattPerM2(150);
                }else if(power>180){
                    setMaxWattPerM2(180)
                }else {
                    setMaxWattPerM2(power)
                }
            }
        } else if (calculatorType === "heating-mat") {
            if (floor.value === "tiles") {
                setMaxWattPerM2(120);
            } else {
                setMaxWattPerM2(80);
            }
        }
    }, [floor, concreteType,power])


    useEffect(() => {
        getRoomData();
    }, [id]);

    useEffect(() => {
        if (calculatorType === "concrete") {
            if (selectedRoom) {
                if (room && room.roomProp && room.roomProp.selectedRoom.value === selectedRoom.value && room.roomProp.selectedRoom.concrete === true && floorChange === false && cableChange === false && concreteTypeChange === false && powerChange === false) {
                    const defaultFloor = room.roomProp.selectedRoom.floorType.find(floor => floor.selected);
                    setFloor(defaultFloor);
                    if (room.roomProp.power) {
                        setPower(room.roomProp.power)
                    } else {
                        setPower(defaultFloor.powerReq);
                    }
                    setCableType(room.roomProp.selectedRoom.cableType.find((cable) => cable.selected));
                    setConcreteType(room.roomProp.concreteType);
                    setVoltageWarnYellow(room.roomProp.warningState);

                } else {
                    const defaultFloor = selectedRoom.floorType.find(floor => floor.selected);
                    setFloor(defaultFloor);

                    if (floorChange || userGivenPower === 0) {
                        setPower(defaultFloor.powerReq);
                    } else {
                        setPower(userGivenPower);
                    }

                    // setPower(defaultFloor.powerReq);

                    setCableType(selectedRoom.cableType.find((cable) => cable.selected));
                    setFloorChange(false);

                }
            }
        } else if (calculatorType === "lamiflex") {

            if (selectedRoom) {
                if (room && room.roomProp && room.roomProp.selectedRoom.value === selectedRoom.value && room.roomProp.selectedRoom.concrete === false && lamiflexPowerChange === false) {
                    // setLamiflexPower(room.roomProp.power)
                    // setFloor(room.roomProp.selectedRoom.floorType.find((floor) => floor.selected))
                    if (room.roomProp.selectedRoom.powerReq) {
                        setLamiflexPower(room.roomProp.selectedRoom.powerReq.find((power) => power.selected)?.value)
                    }
                    setLamiflexClipsArea(room.roomProp.lamiflexClipsArea)
                    setLamiflexClips(room.roomProp.lamiflexClips)
                    if (!room.roomProp.lamiflexArea) {
                        setLamiflexArea(room.areaGFA)
                    }
                    // setLamiRoom(false);

                } else {
                    // setFloor(selectedRoom.floorType.find((floor) => floor.selected))
                    if (selectedRoom.powerReq) {
                        setLamiflexPower(selectedRoom.powerReq.find((power) => power.selected)?.value)
                    }
                }
            }
            if (room) {
                let lamiArea = parseFloat(room.areaGFA) - room.clipArea
                if (lamiArea % 1 !== 0) {
                    setLamiflexCableArea(lamiArea.toFixed(2));
                } else {
                    setLamiflexCableArea(lamiArea);
                }
                // setLamiflexCableArea((room.areaGFA) - room.clipArea);

            }
        } else if (calculatorType === "heating-mat") {
            if (room && room.roomProp && room.roomProp.selectedRoom.concrete === false && room.roomProp.selectedRoom.heat === true && floorChange === false && cableChange === false && underFloorChange === false) {
                setSelectedRoom(room.roomProp.selectedRoom)
                setFloor(room.roomProp.selectedRoom.floorType.find(f => f.selected))
                setCableType(room.roomProp.selectedRoom.cableType.find((cable) => cable.selected))
            } else {
                roomProps.map((r, index) => {
                    if (!selectedRoom)
                        setSelectedRoom(r)
                    setSelectedRoomType(r.value)

                    if (selectedRoom) {
                        setFloor(selectedRoom.floorType.find(f => f.selected))
                        setCableType(selectedRoom.cableType.find((cable) => cable.selected))
                        if (floor.value !== "tiles") {
                            const c = {...cableType, value: '4', name: 'ØS Varmematte-80'}
                            setCableType(c)
                        }
                        setPower(selectedRoom.cableType.find(c => c.selected).powerReq)
                        setVoltageWarnRed(false);
                        setVoltageWarnRedBelow(false);
                        setVoltageWarnYellow(false);

                    }
                    // else {
                    // setFloor(r.floorType.find(f => f.selected))
                    // setCableType(r.cableType.find((cable) => cable.selected))
                    // setPower(r.floorType[0].powerReq)
                    // }
                })
            }
        }
    }, [selectedRoom, room, calculatorType, roomProps, concreteType])

    useEffect(() => {
        setSelectedRoom(roomProps.find((room) => room.value === selectedRoomType))
    }, [selectedRoomType, roomProps])

    useEffect(() => {
        if (room && room.roomProp) {
            setCalculatorType(room.calculatorType);
            setSelectedRoom(room.roomProp.selectedRoom)
            setSelectedRoomType(room.roomProp.selectedRoom.value)
        } else {
            setSelectedRoomType("living_room")
        }
    }, [room])

    useEffect(() => {
        let updatedCablesForUnderFloor;
        let defaultCableTypes;

        if (room && room.roomProp && room.roomProp.underFloorState && underFloorChange === false && room.calculatorType === calculatorType && roomTypeChange === false) {
            setUnderFloor(room.roomProp.underFloorState)
            setConcreteTypeChange(true);
        }


        const defaultRoom = roomProps.find((room) => room.value === selectedRoomType);
        if (defaultRoom) {
            defaultCableTypes = defaultRoom.cableType
        }
        if (defaultRoom) {
            if (underFloor === "flammable" && calculatorType === "concrete" && concreteType === "low-profile" && concreteTypeChange && underFloorChange) {
                if (defaultCableTypes && defaultCableTypes.find(c => c.value === "1" || c.value === "13") !== undefined) {
                    let filteredCablesForUnderFloor = defaultCableTypes.filter(c => c.value !== "1" && c.value !== "13");
                    updatedCablesForUnderFloor = filteredCablesForUnderFloor.map(cable => {
                        let updatedName = cable.name.replace(" (anbefalt)", "");
                        if (cable.value === "2") {
                            updatedName += " (anbefalt)";
                        }

                        return {
                            ...cable,
                            name: updatedName,
                            selected: cable.value === "2"
                        };

                    });
                    // console.log("updatedCablesForUnderFloor1", updatedCablesForUnderFloor);
                }
            } else if (underFloor === "flammable" && calculatorType === "heating-mat" && underFloorChange && floor.value === "tiles") {
                let filteredCablesForUnderFloor = defaultCableTypes.filter(c => c.value !== "5");
                updatedCablesForUnderFloor = filteredCablesForUnderFloor
                // console.log("updatedCablesForUnderFloor2", updatedCablesForUnderFloor);

            } else {
                // console.log("defaultCableTypes",defaultCableTypes);

                updatedCablesForUnderFloor = defaultCableTypes
            }
        }
        if (selectedRoom && underFloorChange) {
            setSelectedRoom(prevRoom => ({
                ...prevRoom,
                cableType: updatedCablesForUnderFloor
            }));
        }
    }, [underFloor, concreteType, calculatorType, floor])

    useEffect(() => {
        if (calculatorType === "concrete") {
            if (power >= 100 && selectedRoom && floor.value !== "linoleum" && powerChange === true) {
                if (room && room.roomProp && room.roomProp.floor.value !== "linoleum") {
                    const updatedCableTypes = room.roomProp.selectedRoom.cableType.map(cable => {
                        let updatedName = cable.name.replace(" (anbefalt)", "");
                        if (cable.value === "1") {
                            updatedName += " (anbefalt)";
                        }
                        return {
                            ...cable,
                            name: updatedName,
                            selected: cable.value === "1"
                        };
                    });
                    const updatedCable = updatedCableTypes && updatedCableTypes.find((c) => c.value === "1")
                    setCableType(updatedCable)
                    setSelectedRoom(prevRoom => ({
                        ...prevRoom,
                        cableType: updatedCableTypes,
                    }));
                } else {
                    const updatedCableTypes = selectedRoom.cableType && selectedRoom.cableType.map(cable => {
                        let updatedName = cable.name.replace(" (anbefalt)", "");
                        if (cable.value === "1") {
                            updatedName += " (anbefalt)";
                        }

                        return {
                            ...cable,
                            name: updatedName,
                            selected: cable.value === "1"
                        };

                    });
                    const updatedCable = updatedCableTypes && updatedCableTypes.find((c) => c.value === "1")
                    setCableType(updatedCable)
                    setSelectedRoom(prevRoom => ({
                        ...prevRoom,
                        cableType: updatedCableTypes,
                    }));
                }
            } else if (power < 100 && selectedRoom && powerChange === true) {
                const defaultRoom = roomProps.find((room) => room.value === selectedRoomType)
                const defaultCableTypes = defaultRoom.cableType;
                let cablesForLowVoltage;

                if (selectedRoom.cableType.find((cable) => cable.selected !== "2")) {
                    cablesForLowVoltage = selectedRoom.cableType && selectedRoom.cableType.map(cable => {
                        let updatedName = cable.name.replace(" (anbefalt)", "");
                        if (cable.value === "2") {
                            updatedName += " (anbefalt)";
                        }
                        return {
                            ...cable,
                            name: updatedName,
                            selected: cable.value === "2"
                        };
                    });
                } else {
                    cablesForLowVoltage = defaultCableTypes;
                }

                setSelectedRoom(prevRoom => ({
                    ...prevRoom,
                    cableType: cablesForLowVoltage,
                }));


                setCableType(selectedRoom.cableType.find((cable) => cable.selected));
            }
            // else {
            //     setCableType(defaultCableTypes);
            // }
        }
    }, [power])

    useEffect(() => {
        if (calculatorType === "lamiflex") {
            if (room && room.clipsArray) {
                let area = parseFloat(room.areaGFA) - lamiflexClipsArea;
                if (area % 1 !== 0) {
                    setLamiflexArea(area.toFixed(2));
                } else {
                    setLamiflexArea(area);
                }
            }
        }
    }, [lamiflexClipsArea])

    const getRoomData = () => {
        if (id) {
            getProject(id)
                .then((res) => {
                    let roomData = res.data
                    if (roomData)
                        setRoom(roomData);
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
    }
    const handleChecked = (e) => {
        setCalculatorType(e.target.value);
    }
    const handleRoomChange = (event) => {
        const selectedRoomValue = event.target.value;
        setSelectedRoomType(selectedRoomValue);
        setUserGivenPower(0);
        setVoltageWarnRed(false);
        setVoltageWarnRedBelow(false);
        setVoltageWarnYellow(false);
        setUnderFloor("non-flammable");
        setRoomTypeChange(true);
    };
    const handleFloorChange = (e) => {
        const selectedFloorValue = e.target.value;
        let updatedCableTypesForLinoleum;
        const updatedFloorTypes = selectedRoom.floorType.map(floor => ({
            ...floor,
            selected: floor.value === selectedFloorValue
        }));

        const defaultRoom = roomProps.find((room) => room.value === selectedRoomType)
        const defaultCableTypes = defaultRoom.cableType
        if (selectedFloorValue === "linoleum" && calculatorType === "concrete") {
            let filteredCableTypesForLinoleum = selectedRoom.cableType.filter(c => c.value !== "1" && c.value !== "13");

            updatedCableTypesForLinoleum = filteredCableTypesForLinoleum.map(cable => {
                let updatedName = cable.name.replace(" (anbefalt)", "");
                if (cable.value === "2") {
                    updatedName += " (anbefalt)";
                }

                return {
                    ...cable,
                    name: updatedName,
                    selected: cable.value === "2"
                };

            });
            // console.log("updatedCableTypesForLinoleum",updatedCableTypesForLinoleum);
        } else if (selectedFloorValue === "wood" && calculatorType === "concrete" && selectedRoom.value === "wc") {
            updatedCableTypesForLinoleum = defaultCableTypes.map(cable => {
                let updatedName = cable.name.replace(" (anbefalt)", "");
                if (cable.value === "2") {
                    updatedName += " (anbefalt)";
                }

                return {
                    ...cable,
                    name: updatedName,
                    selected: cable.value === "2"
                };

            });
            // console.log("updatedCableTypesForWC",updatedCableTypesForWC);
        } else {
            updatedCableTypesForLinoleum = defaultCableTypes
        }
        setSelectedRoom(prevRoom => ({
            ...prevRoom,
            floorType: updatedFloorTypes,
            cableType: updatedCableTypesForLinoleum
        }));

        setFloorChange(true);
        setVoltageWarnRed(false);
        setVoltageWarnRedBelow(false);
        setVoltageWarnYellow(false);
        setUserGivenPower(0);
        // if (selectedRoom)
        //     setUnderFloor("non-flammable");
    };
    const handlePowerChange = (e) => {
        const userInputPower = parseFloat(e.target.value);
        setUserGivenPower(userInputPower);
        // console.log("userInputPower", userInputPower)
        const defaultFloor = selectedRoom.floorType.find(floor => floor.selected);
        const defaultPower = defaultFloor.powerReq
        if (!isNaN(userInputPower) && userInputPower > 0) {
            setPower(userInputPower);
        } else {
            setPower(0)
        }

        if (defaultFloor.value === "wood" && selectedRoom.value !== "freezer_room") {
            if (userInputPower > 80) {
                setVoltageWarnRed(true);
                setVoltageWarnYellow(false);
                setVoltageWarnRedBelow(false);
            } else if (userInputPower <= 80 && userInputPower > 60) {
                setVoltageWarnYellow(true);
                setVoltageWarnRed(false);
                setVoltageWarnRedBelow(false);
            } else if (userInputPower < 45 || Number.isNaN(userInputPower)) {
                setVoltageWarnRedBelow(true);
                setVoltageWarnRed(false);
                setVoltageWarnYellow(false);
            } else {
                setVoltageWarnRed(false);
                setVoltageWarnYellow(false);
                setVoltageWarnRedBelow(false);
            }
        } else if (defaultFloor.value === "linoleum" && selectedRoom.value !== "freezer_room") {
            if (userInputPower > 140) {
                setVoltageWarnRed(true);
                setVoltageWarnYellow(false);
                setVoltageWarnRedBelow(false);
            } else if (userInputPower <= 140 && userInputPower > 100) {
                setVoltageWarnYellow(true);
                setVoltageWarnRed(false);
                setVoltageWarnRedBelow(false);
            } else if (userInputPower < 45 || Number.isNaN(userInputPower)) {
                setVoltageWarnRedBelow(true);
                setVoltageWarnRed(false);
                setVoltageWarnYellow(false);
            } else {
                setVoltageWarnRed(false);
                setVoltageWarnYellow(false);
                setVoltageWarnRedBelow(false);
            }
        } else if (defaultFloor.value === "tiles" && concreteType === "traditional" && selectedRoom.value !== "freezer_room") {
            if (userInputPower > 159) {
                setVoltageWarnRed(true);
                setVoltageWarnYellow(false);
                setVoltageWarnRedBelow(false);
            } else if (userInputPower <= 159 && userInputPower > 140) {
                setVoltageWarnYellow(true);
                setVoltageWarnRed(false);
                setVoltageWarnRedBelow(false);
            } else if (userInputPower < 45 || Number.isNaN(userInputPower)) {
                setVoltageWarnRedBelow(true);
                setVoltageWarnRed(false);
                setVoltageWarnYellow(false);
            } else {
                setVoltageWarnRed(false);
                setVoltageWarnYellow(false);
                setVoltageWarnRedBelow(false);
            }
        } else if (defaultFloor.value === "tiles" && concreteType === "low-profile" && selectedRoom.value !== "freezer_room") {
            if (userInputPower > 159) {
                setVoltageWarnRed(true);
            } else if (userInputPower <= 159 && userInputPower > 140) {
                setVoltageWarnYellow(true);
                setVoltageWarnRed(false);
                setVoltageWarnRedBelow(false);
            } else if (userInputPower < 45 || Number.isNaN(userInputPower)) {
                setVoltageWarnRedBelow(true)
                setVoltageWarnRed(false);
                setVoltageWarnYellow(false);
            } else {
                setVoltageWarnRed(false);
                setVoltageWarnYellow(false);
                setVoltageWarnRedBelow(false);
            }
        } else if (selectedRoom.value === "freezer_room") {
            if (userInputPower < 45 || Number.isNaN(userInputPower)) {
                setVoltageWarnRedBelow(false);
                setVoltageWarnYellow(false);
                setVoltageWarnRed(false);
            }
        }
        setPowerChange(true);
    }
    const handleLamiflexPower = (e) => {
        const power = e.target.value;
        const updatedPower = selectedRoom.powerReq.map(p => ({
            ...p, selected: p.value === parseInt(power)
        }));
        // console.log("updatedPower", updatedPower)
        setSelectedRoom(prevRoom => ({
            ...prevRoom,
            powerReq: updatedPower
        }));
        setLamiflexPower(power);
        setLamiFlexPowerChange(true);
    }
    const handleCableChange = (e) => {
        const selectedCableValue = e.target.value;
        const updatedCable = selectedRoom.cableType.find((c) => c.value === selectedCableValue)
        setCableType(updatedCable)
        if (calculatorType === "heating-mat") {
            if (selectedCableValue === "5") {
                setPower(120)
            } else {
                setPower(80)
            }
        }

        const updatedCableTypes = selectedRoom.cableType.map(cable => ({
            ...cable,
            selected: cable.value === selectedCableValue
        }));
        // console.log("updatedCableTypes", updatedCableTypes)
        setSelectedRoom(prevRoom => ({
            ...prevRoom,
            cableType: updatedCableTypes
        }));
        setCableChange(true);
    };
    const handleLamiflexCheckbox = (e) => {
        let array = []
        let sum = 0;
        let selectedClips = document.querySelectorAll('input[type=checkbox]:checked')
        if (selectedClips.length > 0) {
            selectedClips.forEach((c) => {
                array.push({name: c.name, value: c.value, checked: true})
                sum = sum + parseFloat(c.value)
                // console.log("sum", sum)
                setLamiflexClipsArea(sum)
            })
        } else {
            setLamiflexClipsArea(0);
        }
        setLamiflexClips(array)
        // console.log("array of clips", array)
    };
    const toggleConcFloorSelector = (e) => {
        const val = e.target.value
        // const checked = e.target.checked
        setConcreteType(val)
        setConcreteTypeChange(true);
        setVoltageWarnRed(false);
        setVoltageWarnRedBelow(false);
        setVoltageWarnYellow(false);
    }
    const handleCheckedUnderFloor = (e) => {
        setUnderFloor(e.target.value);
        setUnderFloorChange(true);
    }


    const lamiflexCalc = () => {
        if (calculatorType === "lamiflex" && lamiflexCableArea && lamiflexArea && lamiflexPower) {
            getCalculationsForLamiflex(lamiflexCableArea, lamiflexArea, lamiflexPower)
                .then((response) => {
                    // console.log("lamiflex calculations", response.data);
                    let cableCount = response.data.cableCount;
                    let cables = response.data.cables;
                    let totalWatts = response.data.totalWatt;
                    let lengthDiff = response.data.lengthDiff;
                    let warnings = response.data.warnings;
                    let calculations = response.data.calculations;
                    let lamiFlex = response.data.lamiflex;
                    let calcCableLength;
                    let cableDistance;
                    let wattPerMCable;
                    let wattPerM2Cable;
                    if (calculations) {
                        calcCableLength = calculations.length;
                        cableDistance = calculations.spacing;
                        wattPerMCable = calculations.wattPerM;
                        wattPerM2Cable = calculations.wattPerM2
                    }
                    let json = {
                        ...room,
                        calculations: {
                            wattPerM2Cable: wattPerM2Cable,
                            wattPerMCable: wattPerMCable,
                            totalWatts: totalWatts,
                            calcCableLength: calcCableLength,
                            cableDistance: cableDistance,
                            cableCount: cableCount,
                            cables: cables,
                            lengthDiff: lengthDiff,
                            lamiFlex: lamiFlex
                        },
                        roomProp: {
                            selectedRoom: selectedRoom,
                            selectedRoomType: selectedRoomType,
                            floor: floor,
                            power: lamiflexPower,
                            cableType: "",
                            lamiflexCableArea: lamiflexCableArea,
                            lamiflexArea: lamiflexArea,
                            lamiflexClips: lamiflexClips,
                            lamiflexClipsArea: lamiflexClipsArea
                        },
                        calculatorType: calculatorType
                    }
                    postProject(json).then(response => {
                        navigate('/results/' + id, {
                            state: {
                                imageUri: imageUri
                            }
                        })
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    };
    const getCalcResponse = () => {
        if (power && cableType && room.roundedArea) {
            getCalculations(room.roundedArea, room.floorM2, power, maxWattPerM2, cableType.value)
                .then((response) => {
                    // console.log("calculations", response.data);
                    let recommendations = response.data.recommendations[0];
                    let calcCableLength;
                    let wattPerMCable;
                    let cableDistance;
                    let cableCount;
                    let cables = [];
                    let wattPerM2Cable;
                    let totalWatts;
                    if (recommendations) {
                        cableCount = recommendations.cableCount;
                        calcCableLength = recommendations.calculations.length;
                        cableDistance = recommendations.calculations.spacing;
                        wattPerMCable = recommendations.calculations.wattPerM;
                        cables = recommendations.cables;
                        totalWatts = recommendations.totalWatt;
                        if (recommendations.calculations.wattPerM2 % 1 !== 0) {
                            wattPerM2Cable = recommendations.calculations.wattPerM2.toFixed(2);
                        } else {
                            wattPerM2Cable = recommendations.calculations.wattPerM2;
                        }
                    }
                    let json = {
                        ...room,
                        calculations: {
                            wattPerM2Cable: wattPerM2Cable,
                            wattPerMCable: wattPerMCable,
                            totalWatts: totalWatts,
                            calcCableLength: calcCableLength,
                            cableDistance: cableDistance,
                            cableCount: cableCount,
                            cables: cables,
                        },
                        roomProp: {
                            selectedRoom: selectedRoom,
                            floor: floor,
                            power: power,
                            cableType: cableType,
                            // selectedRoomType: selectedRoomType,
                            concreteType: concreteType,
                            warningState: voltageWarnYellow,
                            underFloorState: underFloor
                        },
                        calculatorType: calculatorType
                    }
                    postProject(json).then(response => {
                        navigate('/results/' + id, {
                            state: {
                                imageUri: imageUri
                            }
                        })
                    })
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };


    if (room)
        return (
            <div className={""}>
                <div className={"flex gap-1 p-0 "}>
                    <img
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/calculator/${id}`)
                        }}
                        src={`${process.env.REACT_APP_OV_CALCULATIONS}/calculator/project/${id}/floorplan.png`}
                        alt="image" className={"drawing-img"}/>
                    <div className={"flex-grow"}>
                        <p className={"text-gray-500 text-xs font-bold py-1"}>BEREGNET </p>
                        <table className={"size-table "}>
                            <tbody>
                            <tr>
                                <td>Brutto areal</td>
                                <td className={"num"}><span>{room.areaGFA}</span></td>
                                <td className={"num"}>㎡</td>
                            </tr>
                            <tr className={"text-red-500"}>
                                <td>Areal hindring</td>
                                <td className={"num"}>
                                    <span>{room.clipArea % 1 !== 0 ? room.clipArea.toFixed(2) : room.clipArea}</span>
                                </td>
                                <td className={"num"}>㎡</td>
                            </tr>
                            {calculatorType === "concrete" && (<tr className={"text-red-500"}>
                                <td>Avstand vegg ({room.gap.gapVal} cm)</td>
                                <td className={"num"}><span>{room.wallDistance}</span></td>
                                <td className={"num"}>㎡</td>
                            </tr>)}
                            <tr>
                                <td>Varmekabel</td>
                                <td className={"num "}>
                                    <span
                                        className={"text-base "}>{calculatorType === "lamiflex" ? lamiflexCableArea : room.roundedArea}</span>
                                </td>
                                <td className={"num "}><span className={"text-base "}>㎡</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={"grid grid-cols-3 gap-2"}>
                    <div>
                        <label htmlFor="concrete" className="radio-label">
                            <div className={"flex gap-2 items-start"}>
                                <input
                                    type="radio"
                                    name={"calc"}
                                    id={"concrete"}
                                    value="concrete"
                                    checked={calculatorType === "concrete"}
                                    onChange={handleChecked}
                                    className="peer "/>
                                INNSTØPT VARMEKABEL
                            </div>
                        </label>
                    </div>
                    <div>

                        <label htmlFor="lamiflex"
                               className="radio-label">
                            <div className={"flex gap-2 items-start"}>
                                <input
                                    type="radio"
                                    name={"calc"}
                                    id={"lamiflex"}
                                    value="lamiflex"
                                    checked={calculatorType === "lamiflex"}
                                    onChange={handleChecked}
                                    className="peer "
                                />
                                LAMIFLEX
                            </div>
                            <span>Varme- og trinnlydsgulv </span>
                        </label>
                    </div>
                    <div>
                        <label htmlFor="heating-mat"
                               className="radio-label">
                            <div className={"flex gap-2 items-start"}>
                                <input
                                    type="radio"
                                    name={"calc"}
                                    id={"heating-mat"}
                                    value="heating-mat"
                                    checked={calculatorType === "heating-mat"}
                                    onChange={handleChecked}
                                    className="peer"
                                />
                                VARME-MATTE
                            </div>
                            <span>Ferdiglagt kabel på matte</span>
                        </label>
                    </div>
                </div>

                {calculatorType === "concrete" && (<div className={"flex gap-4"}>
                    <label htmlFor="traditional"
                           className={concreteType === "traditional" ? "standard-button-selector selected-button" : "standard-button-selector"}>Tradisjonell
                        betong
                        <input type={"radio"} id={"traditional"} name={"concrete-floor-type"} value={"traditional"}
                               className={"radio-btn"} hidden={true} onClick={toggleConcFloorSelector}/>
                    </label>
                    <label htmlFor="low-profile"
                           className={concreteType === "low-profile" ? "standard-button-selector selected-button" : "standard-button-selector"}>Lavtbyggende
                        <input type={"radio"} id={"low-profile"} name={"concrete-floor-type"} value={"low-profile"}
                               className={"radio-btn"} hidden={true} onClick={toggleConcFloorSelector}/>
                    </label>
                </div>)}

                {((calculatorType === "concrete" && concreteType === "low-profile") || (calculatorType === "heating-mat")) &&
                    <div className={""}>
                        <div className={"flex justify-between items-center"}>
                            <div className={"block pt-3 text-sm"}>UNDERLAG :</div>
                            <div className={"flex gap-1"}>
                                <div>
                                    <label htmlFor="non-flammable"
                                           className="under-floor-label">
                                        <div className={"flex gap-1  items-center"}>
                                            <input
                                                type="radio"
                                                name={"floor-sub"}
                                                id={"non-flammable"}
                                                value="non-flammable"
                                                checked={underFloor === "non-flammable"}
                                                onChange={handleCheckedUnderFloor}
                                                className="peer"
                                            />
                                            Ubrennbart
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="flammable"
                                           className="under-floor-label">
                                        <div className={"flex gap-1 items-center"}>
                                            <input
                                                type="radio"
                                                name={"floor-sub"}
                                                id={"flammable"}
                                                value="flammable"
                                                checked={underFloor === "flammable"}
                                                onChange={handleCheckedUnderFloor}
                                                className="peer"
                                            />
                                            Brennbart
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>}


                <div className={"properties"}>
                    {(calculatorType === "concrete" || calculatorType === "lamiflex") && (<label htmlFor="room-type">Romtype:
                        <select
                            className={"select-field"}
                            name="room-type"
                            id="room-type"
                            onChange={handleRoomChange}
                            value={selectedRoomType}
                        >
                            {roomProps && roomProps.map((room) => (
                                <option key={room.id} value={room.value}>
                                    {room.name}
                                </option>
                            ))}
                        </select>
                    </label>)}
                    {(calculatorType === "concrete" || calculatorType === "heating-mat") && (
                        <label htmlFor="floor-type">Overgulv:
                            <select className={"select-field"} name="floor-type" id="floor-type" value={floor.value}
                                    onChange={handleFloorChange}>
                                {selectedRoom && selectedRoom.floorType.map((floor, index) => (
                                    <option key={index} value={floor.value}>
                                        {floor.name}
                                    </option>
                                ))
                                }
                            </select>
                        </label>)}
                    {(calculatorType === "lamiflex") && (<label htmlFor="lamiflex-power">Ønsket effekt:
                        <select className={"select-field"} name="lamiflex-power" id="lamiflex-power"
                                value={lamiflexPower}
                                onChange={handleLamiflexPower}>
                            {(selectedRoom && selectedRoom.powerReq) && selectedRoom.powerReq.map((power, index) => (
                                <option key={index} value={power.value}>
                                    {power.value}
                                </option>
                            ))
                            }
                        </select>
                    </label>)}
                    {(calculatorType === "concrete" || calculatorType === "heating-mat") && (
                        <div className={"grid grid-cols-3 gap-1 w-full"}>
                            <div className={calculatorType === "concrete" ? "col-span-2" : "col-span-3"}>
                                <label htmlFor="cable-type col-span-2 ">Velg kabeltype:
                                    <select className={"select-field"} name="cable-type" id="cable-type"
                                            onChange={handleCableChange} value={cableType.value}>
                                        {calculatorType !== "heating-mat" && selectedRoom && selectedRoom.cableType.map((cable, index) => (
                                            <option key={index} value={cable.value}>{cable.name}</option>
                                        ))}
                                        {calculatorType === "heating-mat" && selectedRoom && floor.value === "tiles" &&
                                            selectedRoom.cableType.map((cable, index) => (
                                                <option key={index} value={cable.value}>{cable.name}</option>
                                            ))}
                                        {calculatorType === "heating-mat" && selectedRoom && floor.value !== "tiles" &&
                                            <option key={80} value={"4"}>{"ØS Varmematte-80"}</option>
                                        }
                                    </select>
                                </label>
                            </div>
                            {calculatorType === "concrete" && (<div>
                                <label htmlFor="power-req">Ønsket effekt:
                                    <input id={"power-req"}
                                           className={(voltageWarnRed || voltageWarnRedBelow) ? "voltage-warning" : voltageWarnYellow ? "voltage-warning-yellow" : ""}
                                           value={power} type={"number"} onChange={handlePowerChange}/>
                                </label>
                            </div>)}
                        </div>)
                    }

                    {(calculatorType === "lamiflex") && (<div className={"flex-grow"}>
                        <p className={"text-gray-500 text-xs font-bold py-1"}>LAMIFLEX </p>
                        <table className={"size-table "}>
                            <tbody>
                            <tr>
                                <td>Brutto areal</td>
                                <td className={"num"}><span>{room.areaGFA}</span></td>
                                <td className={"num"}>㎡</td>
                            </tr>
                            {room.clipsArray && room.clipsArray.map((clip, index) => (
                                    <tr key={index}
                                        className={`${(lamiflexClips && lamiflexClips.some(s => s.name === clip.clipName)) ? "text-red-500" : "text-black"}`}>
                                        <td><span>{clip.clipName}</span></td>
                                        <td className={"flex justify-end gap-2"}>
                                            <p className={"font-thin"}>(Trekk fra)</p>
                                            <div className={"flex justify-between w-16"}>
                                                <div>
                                                    <input type="checkbox" name={clip.clipName}
                                                           id={clip.clipName} value={clip.clipArea}
                                                           defaultChecked={((room.roomProp && room.roomProp.lamiflexClips) && (room.roomProp.lamiflexClips.find(s => (s.checked && s.name === clip.clipName))))}
                                                           onChange={(e) => handleLamiflexCheckbox(e)}/>
                                                </div>
                                                <div>
                                                    <span>{clip.clipArea}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={"num"}>㎡</td>
                                    </tr>
                                )
                            )}
                            <tr>
                                <td className={"font-semibold py-1"}>Lamiflex gulv</td>
                                <td className={"num "}><span
                                    className={"text-base "}>{lamiflexArea ? lamiflexArea : ""}</span></td>
                                <td className={"num "}><span className={"text-base "}>㎡</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>)}

                    {calculatorType === "concrete" && (<div className={"flex justify-end"}>
                        {voltageWarnYellow ? (
                            <p className={"text-red-800 text-sm"}>PS: Utenfor vår anbefaling</p>) : voltageWarnRed ? (
                            <p className={"text-red-800 text-sm"}>PS: oversteget maks
                                effekt</p>) : voltageWarnRedBelow ?
                            <p className={"text-red-800 text-sm"}>PS: under minimumseffekt</p> : ""}
                    </div>)}

                </div>
                <div className={"flex gap-4 w-full"}>
                    <Link className={"standard-button"} onClick={(e) => {
                        e.preventDefault();
                        const doesAnyHistoryEntryExist = location.key !== "default";
                        if (doesAnyHistoryEntryExist) {
                            navigate(-1)
                        } else {
                            navigate('/calculator/' + id)
                        }
                    }}
                    >Tilbake</Link>

                    {(calculatorType === "concrete" || calculatorType === "heating-mat") && (
                        <Link className={(voltageWarnRed || voltageWarnRedBelow) ? "btn-disable" : "primary-button"}
                              onClick={(e) => {
                                  if (!voltageWarnRed) {
                                      e.preventDefault();
                                      getCalcResponse();
                                      saveProjectReference(id, projectName, location.pathname, room.areaGFA, room.clipArea, room.roundedArea, room.wallDistance, selectedRoom.name)
                                  }
                              }}
                        >Neste</Link>)}
                    {(calculatorType === "lamiflex") && (
                        <Link className={"primary-button"} onClick={(e) => {
                            e.preventDefault();
                            lamiflexCalc();
                        }}
                        >Neste</Link>)}
                </div>
            </div>
        );
}